import styled from "styled-components";
import { Menu } from "antd";

export const Wrapper = styled.div<{
  $mobile: boolean;
  $ipad: boolean;
  width?: number;
  isDragging?: boolean;
  $coachView?: boolean;
}>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${({ width }) => `${width}px`};
  padding: 12px 0px;
  background-color: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
  border-left: ${({ isDragging }) =>
    isDragging ? "1.5px solid #000" : "1px solid #868f96"};
  transition: border-left 0.2s ease-in-out;
  user-select: none;
  height: calc(100vh - 150px);

  ${({ $coachView }) =>
    $coachView &&
    `
    height: calc(100vh - 224px);
  `}

  @media screen and (max-width: 900px) {
    position: absolute;
    right: -500px;
    top: -147px;
    z-index: 9999999999;
    width: 350px;
    height: 100vh;
    transition: right 0.2s ease-in-out;
    background-color: white;
  }

  @media screen and (max-width: 500px) {
    width: 300px;
    top: -191px;
  }

  ${({ $ipad }) => $ipad && `right: 0px !important;`}
`;

export const SectionDrag = styled.div`
  position: absolute;
  top: 19px;
  left: -13px;
  height: 12px;
  width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  cursor: ew-resize;
`;

export const CursorPointer = styled.div`
  cursor: pointer;
`;

export const CloseIcon = styled.div`
  display: none;
  @media screen and (max-width: 900px) {
    display: block;
  }
`;

export const Title = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  // padding: 4px 12px 14px 12px;
  // border-bottom: 1px solid #CED0D4;
  // margin-bottom: 10px;

  padding: 0 12px;
  margin-bottom: 6px;
`;

export const HeaderTitle = styled.span`
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.oxfordblue.main};

  svg {
    margin-right: 4px;
  }

  @media screen and (max-width: 500px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const TargetSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  padding: 0px 12px;
`;

export const TargetCalanderSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
`;

export const TargetCalander = styled.div`
  height: 28px;
  width: 140px;
  background-color: ${({ theme }) => theme.palette.common.grey};

  @media screen and (max-width: 500px) {
    width: 120px;
  }
`;

export const ProgressSection = styled.div`
  width: 100%;
  border-bottom: 1px solid #bccedd;
  margin-bottom: 12px;
  padding: 0px 12px;
`;

export const ProgressActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 13px;
  margin-bottom: 12px;
`;

export const ProgressAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-size: 11px;
  font-weight: 500;
  line-height: 20px;
  color: #171717;
  font-family: "Inter";

  @media screen and (max-width: 500px) {
    font-size: 9px;
  }

  span {
    font-weight: 400;
  }
`;

export const Dot = styled.div<{ $color?: string }>`
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: ${({ $color }) => $color || "#4F9BE8"};
`;

export const ScheduleSection = styled.div`
  width: 100%;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px 0px 24px;
`;

export const ScheduleTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  color: #737373;
  cursor: pointer;

  @media screen and (max-width: 500px) {
    font-size: 10px;
  }
`;

export const ScheduleButton = styled.div`
  background-color: ${({ theme }) => theme.schedular_palette_dark.active};
  color: ${({ theme }) => theme.palette.primary.main};
  padding: 6px 12px 6px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  transition: linear 0.2s;
  border-radius: 6px;

  @media screen and (max-width: 500px) {
    font-size: 10px;
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const ActivitiesSection = styled.div`
  width: 100%;
  overflow-y: auto;
  max-height: 300px;
  height: 300px;
  background-color: lightblue;
`;

interface IActivitiesList {
  $pending?: boolean;
  $completed?: boolean;
}

export const ActivitiesList = styled.div<IActivitiesList>`
  position: relative;
  width: 100%;
  height: 60px;
  border-radius: 2px;
  padding: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  margin: ${({ $completed }) => $completed ? "0px 0px 8px 0px" : "0px 0px 8px 0px"};
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
  top: 7px;
  background-color: ${({ theme }) => theme.schedular_palette_dark.background.light};
  background: ${({ theme }) => theme.palette.common.white};

  ${({ $pending }) => $pending && `
    box-shadow: 0px 0px 0px 3px rgba(236, 163, 54, 0.15);
    border: 1px solid var(--warning-500-main, #F90);
    padding-left: 6px;
  `}
`;

export const ListLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
`;

export const ListRight = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
`;

export const ListDrag = styled.div`
  background-color: ${({ theme }) => theme.palette.common.grey};
  border-radius: 3px;
  padding: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 13px;
  height: 55px;

  span,
  h4 {
    width: 18px;
    height: 18px;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    line-height: 9px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    padding: 4px;
    background-color: #4c545b;
    color: ${({ theme }) => theme.palette.common.white};
  }

  span {
    font-size: 10px;
  }

  h4 {
    font-size: 12px;
  }
`;

export const ListAvatar = styled.div`
  background-color: #ebeef2;
  padding: 3px, 4px, 3px, 4px;
  border-radius: 2px;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4f9be8;
  text-transform: capitalize;
  font-size: 10px;
  line-height: 10px;
  font-weight: 500;
  font-family: "Inter", sans-serif;

  @media screen and (max-width: 500px) {
    font-size: 8px;
  }
`;

export const ListTitle = styled.div<{
  completed?: boolean;
}>`
  flex: 1;
  color: #05192e;
  font-size: 12px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-break: break-word;
  -webkit-line-clamp: 2;
  text-decoration: ${({ completed }) => (completed ? "line-through" : "none")};

  @media screen and (max-width: 500px) {
    font-size: 10px;
  }
`;

export const ListTime = styled.div`
  white-space: nowrap;
  font-size: 11px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Inter", sans-serif;
  color: #000000;
  min-width: 50px;
  overflow: hidden;

  @media screen and (max-width: 500px) {
    font-size: 9px;
  }
`;

export const ListCheck = styled.div`
  .ant-checkbox-inner {
    width: 20px !important;
  }
`;

export const PriorityWrapper = styled.div`
  margin: 8px 0;
  height: 60px;
  width: 100%;
  background-color: ${({ theme }) =>
    theme.schedular_palette_dark.background.light};
  display: flex;
  border-radius: 6px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  position: relative;
  background: ${({ theme }) => theme.palette.common.white};
  margin-right: 5px;
  align-items: center;
`;

export const AreaBadge = styled.div<{ color: string }>`
  min-width: 5px;
  height: 37px;
  border-radius: 0px 4px 4px 0px;
  margin-right: 9px;
  background: ${({ color }) => (color ? color : "#fff")};
`;

export const ScheduleListContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  padding: 0px 12px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #ced4da;
  }

  &::-webkit-scrollbar-track {
    background-color: #f8f9fa;
  }

  scrollbar-width: thin;
  scrollbar-color: #ced4da #f8f9fa;
`;

export const RelativeWrapper = styled.div`
  position: relative;
  min-height: 200px;
  max-height: max-content;
  padding: 0px 4px;
`;

export const HiddenBox = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
`;

export const EmptyText = styled.div`
  /* position: absolute; */
  /* top: 50px; */
  /* left: 33%; */
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #798b99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const BackgroundSkeleton = styled.div`
  margin: 8px 0;
  height: 60px;
  display: flex;
  border-radius: 6px;
  background: #c1cbd4;
  opacity: 10%;
  margin-right: 5px;
`;

export const EmptyBox = styled.div<{ $emptyBox?: boolean }>`
  margin: 6px 0;
  height: 60px;
  display: flex;
  border-radius: 6px;
  margin-right: 5px;
  border: 6px solid #99a8b720;
  /* border: 6px solid #99a8b7; */
  /* opacity: 10%; */
  background: #fff;
  align-items: center;
  justify-content: center;

  ${({ $emptyBox }) =>
    $emptyBox &&
    `
    height: 188px;
  `}
`;

export const Body = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

export const IndexBar = styled.div`
  width: 20px;
  height: 20px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 10px;
  line-height: 9px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  padding: 5px 6px;
  margin: 6px 7px 4px 5px;
  background-color: #4c545b;
  color: ${({ theme }) => theme.palette.common.white};
`;

export const PriorityName = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
`;

export const PriorityListType = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  text-transform: capitalize;
  color: #4f9be8;

  &.bold {
    font-weight: 700;
  }
`;

export const PriorityCircle = styled.div<{ $priority: string }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;

  ${({ $priority }) =>
    $priority &&
    $priority === "high" &&
    `
    background-color: #FF0000;
  `};

  ${({ $priority }) =>
    $priority &&
    $priority === "medium" &&
    `
    background-color: #FEAB3D;
  `};

  ${({ $priority }) =>
    $priority &&
    $priority === "low" &&
    `
    background-color: #8E97A3;
  `};
`;

export const PriorityTitle = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  span {
    flex: 1;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const FooterText = styled.div`
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #798b99;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 12px;

  @media screen and (max-width: 500px) {
    font-size: 10px;
  }
`;

export const Targett = styled.div`
  display: flex;
  gap: 10px;
`;

export const Clos = styled.div`
  cursor: pointer;
`;

export const CustomMenu = styled(Menu)`
  width: 100% !important;
`;

export const Check = styled.div`
  margin-left: 16px;

  .ant-checkbox-inner {
    width: 20px !important;
  }
`;

export const DropDownStyle = styled.div`
  background-color: orange;

  .ant-dropdown-menu {
    background-color: orange !important;
  }
`;

export const CompletedHeading = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: #262626;
  padding-top: 10px;

  @media screen and (max-width: 500px) {
    font-size: 11px;
  }
`;