import { FC, useEffect, useState } from "react";
import moment from "moment";
import { Tooltip, Checkbox } from "elements";

import {
  Wrapper,
  Goal,
  GoalLabel,
  GoalTitle,
  GoalProgress,
  ItemWrapper,
  Item,
  ItemCol,
  ItemTitle,
  ItemTitleWrap,
  ItemData,
  ItemDataTitle,
  ItemDataValue,
  ItemDeleteBtn,
  CheckboxWrapper
} from "./styled";

import { Hla, Type } from "../../../Elements";

import RadialBar from "components/PriorityApp/SimpleView/RadialBar";
import GoalPopover from "components/PriorityApp/SimpleView/GoalPopover";

// @ts-ignore
import { ReactComponent as CloseIcon } from "assets/images/priorities/close.svg";

// @ts-ignore
import { ReactComponent as RestoreIcon } from "assets/images/priorities/restore.svg";

import { IPrioritizedActivity, ITask } from "Redux/APM/ListFocusedExtremes/type";
import DurationForm from "../DurationForm";
import DelegatePopover from "../DelegatePopover";

interface IProps {
  id?: string;
  activity: IPrioritizedActivity | null;
  task: ITask | null;
  checked?: boolean;
  duration?: number;
  isRemoved?: boolean;
  isStandalone?: boolean;
  // priority: number;
  showGoal?: boolean;
  showDetails?: boolean;
  showDelegatePopover?: boolean;
  adjustDuration?: boolean;
  showRemoveBtn?: boolean;
  showHLA?: boolean;
  showSeeGoal?: boolean;
  showCheckbox?: boolean;
  requiredMins?: number;
  onTimeChange?: (id: string, itemId: string, value: number, original: number, delegated?: number) => void;
  // onDelegatedTimeChange?: (id: string, value: number) => void;
  onRemove?: (id: string, time: number) => void;
  onCheckboxChange?: (id: string, checked: boolean) => void;
}

/* const convertMinutesToTimeFormat = (time?: number | number) => {
  if (!time) return "0h";

  const hours = Math.floor(time / 60);
  const mins = time % 60;

  let result = "";

  if (hours) {
    result += `${hours}h`;
  }

  if (mins) {
    if (result) result += " ";
    result += `${mins}m`;
  }

  return result || `0h`;
}; */

/**
 * Retrieves the value from either 'activity' or 'task' based on given keys, or returns a default value.
 * @returns {*} The value from either activity or task, or the default value.
 */
/* const getDefault = (
  activity: IPrioritizedActivity | null,
  task: ITask | null,
  activityKey: string,
  taskKey: string = '',  // Providing a default value here to avoid 'undefined'.
  defaultValue: string
): string => {
  if (!taskKey) taskKey = activityKey;  // Ensure taskKey is never undefined.
  return activity?.[activityKey] ?? task?.[taskKey] ?? defaultValue;
};
 */
const ActivityItem: FC<IProps> = ({
  id,
  activity,
  task,
  checked,
  duration,
  isRemoved,
  isStandalone,
  showGoal,
  showDetails,
  showDelegatePopover,
  adjustDuration,
  showRemoveBtn,
  showHLA,
  showSeeGoal,
  showCheckbox,
  requiredMins = 0,
  onTimeChange,
  // onDelegatedTimeChange,
  onRemove,
  onCheckboxChange,
}) => {
  const [time, setTime] = useState(0);
  const [customTime, setCustomTime] = useState(0);
  const [isDeleted, setIsDeleted] = useState(false);

  const itemId = activity ? activity.id : task ? task.id : '';
  const itemName = activity ? activity.action.context : task ? task.name : '';
  const itemTime = activity ? activity.total_time : task ? task.estimated_duration : 0;
  const itemType = activity ? activity.action.type : task ? 'Task' : '';
  const itemDeadline = activity ? activity.deadline : task ? task.deadline : '';
  const itemPriority = activity ? parseInt(activity.calculated_priority) : 0;

  const goal_name = activity ? activity.goal_name : '';
  const goal_color = activity ? activity.color : '';

  const goalPopoverData = {
    area_name: activity?.area_name,
    action: { context: itemName },
    category_name: activity?.category_name,
    goal_name: goal_name,
    color: goal_color
  }

  useEffect(() => {
    setTime(itemTime);
    setCustomTime(duration || itemTime);
    setIsDeleted(isRemoved || false);
  }, [duration, isRemoved, itemTime]);

  const handleAfterTimeChange = (value: number) => {
    if (onTimeChange && (id || isStandalone)) {
      setCustomTime(value);
      onTimeChange(id ? id : '', itemId, value, itemTime);
    }
  };

  const handleDelete = () => {
    if (onRemove && id) {
      setIsDeleted((prev) => !prev);
      onRemove(id, itemTime);
    }
  };

  const handlePopoverChange = (estimated: number, delegated: number) => {
    setCustomTime(estimated);
    onTimeChange && onTimeChange(id || '', itemId, estimated, itemTime, delegated);
  }

  return (
    <Wrapper>
      {showGoal ? (
        <Goal $color={goal_color}>
          <GoalLabel>Goal</GoalLabel>
          <GoalTitle>{goal_name || 'Unnamed goal'}</GoalTitle>
        </Goal>
      ) : null}

      <ItemWrapper $active={showGoal}>
        <Item $active={requiredMins > 0} $green={showGoal && requiredMins <= 0} $deleted={isDeleted}>
          <ItemCol>
            {showCheckbox ? (
              <CheckboxWrapper>
                <Checkbox
                  id={`pic_${id}`}
                  checked={checked}
                  onChange={onCheckboxChange ? () => onCheckboxChange(id || '', !checked) : undefined}
                />
              </CheckboxWrapper>
            ) : null}

            {showDetails ? null : (
              <GoalProgress>
                <Tooltip title="Priority Score">
                  <div>
                    <RadialBar value={itemPriority} />
                  </div>
                </Tooltip>
              </GoalProgress>
            )}

            {/* <ItemOrder>{priority}</ItemOrder> */}
            <ItemTitleWrap>
              <Type type={itemType} />
              <ItemTitle title={itemName}>{itemName}</ItemTitle>
            </ItemTitleWrap>

            {activity && !showGoal && showSeeGoal ? <GoalPopover data={goalPopoverData} /> : null}

            {activity && showHLA && parseInt(activity.indirect_impact) > 5 ? (
              <Hla indirectImpact={parseInt(activity.indirect_impact)} />
            ) : null}
          </ItemCol>

          {showDetails ? (
            <ItemCol $secondary>
              <ItemData>
                <ItemDataTitle>Deadline</ItemDataTitle>
                <ItemDataValue>
                  {itemDeadline
                    ? moment(itemDeadline).format("MM/DD/YY")
                    : "-"}
                </ItemDataValue>
              </ItemData>

              <ItemData>
                <ItemDataTitle>Est. Duration</ItemDataTitle>
                <DurationForm
                  value={time}
                  onChange={handleAfterTimeChange}
                />
              </ItemData>
            </ItemCol>
          ) : null}

          {adjustDuration ? (
            <ItemCol $tertiary>
              <DurationForm
                value={customTime}
                max={itemTime}
                disabled={isDeleted || !time}
                showSlider
                onChange={handleAfterTimeChange}
              />

              {showDelegatePopover ? null : <ItemDeleteBtn
                title={isDeleted ? "Restore" : "Remove from Focused"}
                onClick={handleDelete}
                $disabled={!showRemoveBtn}
              >
                {isDeleted ? <RestoreIcon /> : <CloseIcon />}
              </ItemDeleteBtn>}

              {showDelegatePopover && (task ? <div style={{ width: 21 }} /> : <DelegatePopover
                estimated={customTime}
                delegated={activity?.management_time || 0}
                disabled={!itemTime}
                onChange={handlePopoverChange}
              />)}
            </ItemCol>
          ) : null}
        </Item>
      </ItemWrapper>
    </Wrapper>
  );
};

export default ActivityItem;
