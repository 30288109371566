import styled from "styled-components";

export const Wrapper = styled.div<{ $color?: string }>`
  max-width: 270px;
  height: fit-content;
  max-height: 500px;
  border-radius: 4px;
  padding: 14px;
  background-color: #FFFFFF;
  gap: 4px;
  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25) !important;

  ${({ $color }) => $color && `
    border-top-color: ${$color};
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline
  height: 20px
`;

export const Area = styled.span`
  color: #6D7784;
  font-family: Inter;
  font-size: 11px;
  font-weight: 700;
  line-height: 20px;
`;

export const Category = styled.span`
  color: #6D7784;
  font-family: Inter;
  font-size: 11px;
  font-weight: 500;
  line-height: 20px;
`;

export const Title = styled.span`
  color: var(--Neutral-800, #262626);
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  margin: 4px 0;
  padding-bottom: 4px;
  border-bottom: 1px solid var(--Color-3, #D9D9D9);
`;

export const DateLabel = styled.span`
  color: var(--Neutral-500, #737373);
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
`;

export const Date = styled.span`
  color: var(--Neutral-900, #171717);
  font-family: Inter;
  font-size: 12px;
  line-height: 20px;
`;

export const Goal = styled.button<{ $visible?: boolean }>`
  padding: 0;
  border: none;
  background-color: transparent;
  margin-left: 7px;
  visibility: hidden;
  display: flex;
  justify-content: space-between;
  width: 74px;
  align-items: center;
  margin-top: 4px;

  ${({ $visible }) => $visible && `
    visibility: visible !important;
  `}
`;

export const GoalTitle = styled.div`
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 11px;
  line-height: 20px;
  color: #6D7784;
  margin-right:10px;
`;
