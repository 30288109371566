import React, { forwardRef, useEffect } from "react";
import { Checkbox, Tooltip, Dropdown as DropdownElement } from "elements";
import moment from "moment";
import useDeviceType from "../../../../Hooks/ResponsiveHook";
import { Draggable } from "react-beautiful-dnd";

import { Dropdown, Space } from "antd";

import InputAreaAI from "components/InputAreaAI";

import Drag from "assets/images/dragicon.svg";
import ChevronRightIcon from "assets/images/journeys/chevron-right.svg";
import { ReactComponent as WarningIcon } from "assets/images/journeys/warning.svg";

import * as Layout from "./styled.js";
import * as Styles from "../styled.js";

import CustomDatepicker from "../CustomDatepicker";
import AccountabilitySelect, { getGroupedOptions, getOptionWithLabel } from "../AccountabilitySelect";

const getItemStyle = (isDragging, draggableStyle) => {
  let copyDraggableStyle = draggableStyle;
  copyDraggableStyle.position = isDragging && "fixed";

  return {
    userSelect: "none",
    // background: "white",
    ...copyDraggableStyle,
  };
};

const ActionTypes = [
  {
    key: "Habit",
    element: "Habit",
  },
  {
    key: "Decision",
    element: "Decision",
  },
  {
    key: "Action Item",
    element: "Action",
  },
];

const withDraggable = (Component) => {
  const DraggableComponent = (
    { draggableId, index, isDragDisabled, ...props },
    ref
  ) => (
    <Draggable
      draggableId={draggableId}
      index={index}
      isDragDisabled={isDragDisabled}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
        >
          <Component
            ref={ref}
            {...props}
            provided={provided}
            snapshot={snapshot}
          />
        </div>
      )}
    </Draggable>
  );

  return forwardRef(DraggableComponent);
};

const ActionItem = forwardRef(
  (
    {
      action,
      actionIndex,
      selectedGoalAction,
      accountabilities,
      customAccountabilities,
      domainId,
      canBeDeleted,
      onChange,
      onAccountabilityCreate,
      onFocus,
      onMenuClick,
      onAssistantChange,
      onAssistantSelect,
      provided: swap,
    },
    { contextRefs }
  ) => {
    const $input = React.useRef();

    /* const strategiesOptions = strategies
      .sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
      ?.map((acc) => {
        return {
          key: acc?.name,
          element: acc?.name,
        };
      }); */

    const handleAssistantSelect = (items) => {
      onAssistantSelect(items, action.id);
    };

    const onSelect = item => {
      let label = item?.label;

      if (typeof item?.label !== 'string') {
        label = item?.label.props.label;
      }

      onChange(
        {
          target: {
            value: label,
            name: "accountability",
          },
        },
        selectedGoalAction?.id,
        action?.id,
        selectedGoalAction?.i,
        actionIndex
      );
    }

    const onDeselect = () => {
      onChange(
        {
          target: {
            value: "",
            name: "accountability",
          },
        },
        selectedGoalAction?.id,
        action?.id,
        selectedGoalAction?.i,
        actionIndex
      );
    }

    let actionDeadline = moment(action.deadline);
    let goalDeadline = moment(selectedGoalAction.goal.deadline);

    let minStartline = moment(selectedGoalAction.goal.startline).toDate();
    let maxStartline =
      action.deadline && selectedGoalAction.goal.deadline
        ? moment.min(actionDeadline, goalDeadline).subtract(1, "days").toDate()
        : action.deadline
        ? moment(action.deadline).subtract(1, "days").toDate()
        : selectedGoalAction.goal.deadline
        ? moment(selectedGoalAction.goal.deadline).subtract(1, "days").toDate()
        : undefined;

    const screenSizes = useDeviceType();
    const isMobile = screenSizes === "mobile";
    const isTablet = screenSizes === "iPad";
    const isLargTablet = window.innerWidth > 1024 && window.innerWidth < 1256;

    return (
      <Styles.ContentOne
        $color={selectedGoalAction?.color}
        $isHighlighted={
          (actionIndex === 0 || actionIndex === 1) && !action?.completed
        }
      >
        <Styles.ActionRow $isFirst={actionIndex === 0}>
          <Styles.ActionsWrapper $first={isTablet || isMobile ? null : true}>
            <Styles.ActionCol $flex>
              <Styles.ActionContainer>
                {/* <Styles.ActionStatusIcon
                    src={action?.completed ? CompletedIcon : OngoingIcon}
                    alt=""
                    title={action?.completed ? "Completed" : "Ongoing"}
                  /> */}

                {!isMobile && !isTablet && (
                  <Styles.ActionHeading>{actionIndex + 1}</Styles.ActionHeading>
                )}
                <Styles.ActionMobile $title>
                  <Styles.Label $mobile $half $right $width={120}>6. Enter Actions</Styles.Label>
                  <Styles.Label $mobile $half $left>7. Prioritize</Styles.Label>
                  {isTablet && <Styles.InfoWrapper $end>
                    <Space direction="horizontal">
                      <Dropdown.Button
                        overlayClassName="row_edit"
                        trigger={"onclick"}
                        menu={{
                          items: [
                            {
                              key: "2",
                              label: "DELETE",
                              disabled: !canBeDeleted,
                            },
                          ],
                          onClick: (item) =>
                            onMenuClick(
                              selectedGoalAction?.id,
                              action?.id,
                              selectedGoalAction?.i,
                              item.key,
                              actionIndex,
                              true
                            ),
                        }}
                      ></Dropdown.Button>
                    </Space>
                  </Styles.InfoWrapper>}
                </Styles.ActionMobile>
                <Styles.InputField>
                  {(isMobile ||
                    isTablet) && (
                      <Styles.ActionHeading>
                        {actionIndex + 1}
                      </Styles.ActionHeading>
                    )}
                  {action?.completed ? (
                    <Styles.ActionName>{action?.context}</Styles.ActionName>
                  ) : (
                    <InputAreaAI
                      id={`action_${action?.id}`}
                      placeholder="Add Action..."
                      disabled={action?.completed}
                      value={action?.context}
                      size="sm"
                      ref={$input}
                      max={255}
                      assistant={{
                        type: "action",
                        color: selectedGoalAction?.color,
                        title: "Actions",
                        order: selectedGoalAction?.goal?.priority,
                        area: selectedGoalAction.name,
                        category: selectedGoalAction?.goal?.category_name,
                        goal: selectedGoalAction?.goal?.goal_name,
                        actions: selectedGoalAction?.goal?.actions.filter(act => act.id !== action.id).map(action => action.context),
                        domain: domainId,
                        prompt: action?.context,
                        onSelect: handleAssistantSelect
                      }}
                      onChange={(value) =>
                        onChange(
                          {
                            target: {
                              value,
                              name: "context",
                            },
                          },
                          selectedGoalAction?.id,
                          action?.id,
                          selectedGoalAction?.i,
                          actionIndex,
                          false,
                          false
                        )
                      }
                      onBlur={(value) => {
                        console.log("blurred");
                        onChange(
                          {
                            target: {
                              value,
                              name: "context",
                            },
                          },
                          selectedGoalAction?.id,
                          action?.id,
                          selectedGoalAction?.i,
                          actionIndex
                        );
                      }}
                      onKeyDown={(e) =>
                        onFocus(e, actionIndex, "context", "deadline")
                      }
                    />
                  )}
                  {isTablet && (
                    <Styles.InfoWrapper $padding>
                      <Tooltip
                        title={
                          action?.completed
                            ? "Uncomplete action"
                            : "Complete action"
                        }
                        alternative
                      >
                        <Checkbox
                          id={action?.id}
                          checked={action?.completed}
                          onChange={() =>
                            onMenuClick(
                              selectedGoalAction?.id,
                              action?.id,
                              selectedGoalAction?.i,
                              "1",
                              actionIndex,
                              true
                            )
                          }
                        />
                      </Tooltip>
                    </Styles.InfoWrapper>
                  )}
                  {!action?.completed && isMobile && (
                    <Styles.ImgContainer
                      {...swap.dragHandleProps}
                      onMouseDown={(e) => e.currentTarget.focus()}
                    >
                      <Styles.Icon src={Drag} alt="icon" />
                    </Styles.ImgContainer>
                  )}
                </Styles.InputField>
              </Styles.ActionContainer>
            </Styles.ActionCol>
            {!isMobile && !isTablet && (
              <Styles.ActionCol $dnd $width="80px">
                <Styles.Label $mobile>7. Prioritize</Styles.Label>
                {!action?.completed && (
                  <Styles.ImgContainer
                    {...swap.dragHandleProps}
                    onMouseDown={(e) => e.currentTarget.focus()}
                  >
                    <Styles.Icon src={Drag} alt="icon" />
                  </Styles.ImgContainer>
                )}
              </Styles.ActionCol>
            )}
          </Styles.ActionsWrapper>
          {isMobile ||
            (isTablet && (
              <div
                style={{
                  height: 1,
                  width: "100%",
                  backgroundColor: "lightgray",
                  marginBottom: 20,
                  marginTop: 20,
                }}
              />
            ))}

          {isTablet ? (
            <>
              <Styles.ActionsWrapper $action>
                <Styles.ActionCol $padding={1} $width={"17%"}>
                  <Styles.Label $mobile>7. Prioritize</Styles.Label>
                  {!action?.completed && (
                    <Styles.ImgContainer
                      {...swap.dragHandleProps}
                      onMouseDown={(e) => e.currentTarget.focus()}
                    >
                      <Styles.Icon src={Drag} alt="icon" />
                    </Styles.ImgContainer>
                  )}
                </Styles.ActionCol>

                <Styles.ActionCol
                  $padding={1}
                  $startline
                  $width={"20%"}
                >
                  <Styles.Label $mobile>8. Startline</Styles.Label>
                  <Styles.InputField className="datepicker-wrapper">
                    <CustomDatepicker
                      disabled={action?.completed}
                      selected={
                        action.startline
                          ? moment(action.startline).toDate()
                          : null
                      }
                      minDate={minStartline}
                      // maxDate={maxStartline}
                      onChange={(date) => {
                        onChange(
                          {
                            target: {
                              value: moment(date).isValid()
                                ? moment(date).format("YYYY-MM-DD")
                                : null,
                              name: "startline",
                            },
                          },
                          selectedGoalAction?.id,
                          action?.id,
                          selectedGoalAction?.i,
                          actionIndex
                        );
                      }}
                    />
                  </Styles.InputField>
                </Styles.ActionCol>

                <Styles.ActionCol
                  $padding={isMobile || isTablet ? 1 : null}
                  $deadline
                  $width={"20%"}
                >
                  <Styles.Label $mobile>9. Deadline</Styles.Label>
                  <Styles.InputField className="datepicker-wrapper">
                    <CustomDatepicker
                      disabled={action?.completed}
                      selected={
                        action?.deadline
                          ? moment(action?.deadline).toDate()
                          : null
                      }
                      minDate={moment(selectedGoalAction?.goal?.startline).toDate()}
                      maxDate={moment(
                        selectedGoalAction?.goal?.deadline
                      ).toDate()}
                      onChange={(date) => {
                        onChange(
                          {
                            target: {
                              value: moment(date).isValid()
                                ? moment(date).format("YYYY-MM-DD")
                                : null,
                              name: "deadline",
                            },
                          },
                          selectedGoalAction?.id,
                          action?.id,
                          selectedGoalAction?.i,
                          actionIndex
                        );
                      }}
                    />
                  </Styles.InputField>
                </Styles.ActionCol>

                <Styles.ActionCol
                  $padding={isMobile || isTablet ? 1 : null}
                  $accountability
                  $width={"25%"}
                >
                  <Styles.Label $mobile>10. Accountability</Styles.Label>
                  <Styles.MainWrapper>
                    {action?.completed ? action?.accountability : (
                      <AccountabilitySelect
                        options={getGroupedOptions(accountabilities, customAccountabilities, onDeselect)}
                        disabled={action?.completed}
                        value={getOptionWithLabel(action?.accountability, accountabilities, customAccountabilities) || undefined}
                        onSelect={onSelect}
                        onCreate={onAccountabilityCreate}
                      />
                    )}
                  </Styles.MainWrapper>
                </Styles.ActionCol>

                <Styles.ActionCol
                  $padding={isMobile || isTablet ? 1 : null}
                  $width={"20%"}
                  style={{ marginRight: 10 }}
                >
                  <Styles.TableHeadCol>
                    <Styles.Label $mobile>Select Type</Styles.Label>
                    <Tooltip
                      placement="leftTop"
                      type="tertiary"
                      title="Set the type of action:"
                      text={
                        <>
                          <p className="mb-0">Action — an activity you do once.</p>
                          <p className="mb-0">Decision — a choice you have to make.</p>
                          <p className="mb-0">Habit — an action you do repeatedly.</p>
                        </>
                      }
                      align={{
                        offset: [0, -2]
                      }}
                      trigger="click"
                      >
                      <WarningIcon />
                    </Tooltip>
                </Styles.TableHeadCol>
                  
                  <Styles.Checkdiv>
                    <DropdownElement
                      getPopupContainer={(trigger) => trigger.parentElement}
                      menuItems={ActionTypes}
                      disabled={action?.completed}
                      value={action?.type || "Action"}
                      secondary
                      onChange={(value) => {
                        onChange(
                          {
                            target: {
                              value: value.key,
                              name: "type",
                            },
                          },
                          selectedGoalAction?.id,
                          action?.id,
                          selectedGoalAction?.i,
                          actionIndex
                        );
                      }}
                    >
                      <Layout.DropdownToggler
                        $empty={!action?.type}
                        $disabled={action?.completed}
                      >
                        {action?.type
                          ? action.type === "Action Item"
                            ? "Action"
                            : action.type
                          : "Select"}
                        <img src={ChevronRightIcon} alt="" />
                      </Layout.DropdownToggler>
                    </DropdownElement>
                  </Styles.Checkdiv>
                </Styles.ActionCol>
              </Styles.ActionsWrapper>
            </>
          ) : (
            <>
              <Styles.ActionsWrapper style={{marginLeft: '10px'}} $action>
                <Styles.ActionCol
                  $padding={isMobile || isTablet ? 1 : null}
                  $startline
                  $width={(isMobile || isLargTablet) ? "50%" : "7vw"}
                >
                  <Styles.Label $mobile>8. Startline</Styles.Label>
                  <Styles.InputField $leftAlign className="datepicker-wrapper">
                    <CustomDatepicker
                      disabled={action?.completed}
                      selected={
                        action.startline
                          ? moment(action.startline).toDate()
                          : null
                      }
                      minDate={minStartline}
                      // maxDate={maxStartline}
                      onChange={(date) => {
                        onChange(
                          {
                            target: {
                              value: moment(date).isValid()
                                ? moment(date).format("YYYY-MM-DD")
                                : null,
                              name: "startline",
                            },
                          },
                          selectedGoalAction?.id,
                          action?.id,
                          selectedGoalAction?.i,
                          actionIndex
                        );
                      }}
                    />
                  </Styles.InputField>
                </Styles.ActionCol>

                <Styles.ActionCol
                  $padding={isMobile || isTablet ? 1 : null}
                  $deadline
                  $width={isMobile ? "50%" : "7vw"}
                >
                  <Styles.Label $mobile>9. Deadline</Styles.Label>
                  <Styles.InputField $leftAlign className="datepicker-wrapper">
                    <CustomDatepicker
                      disabled={action?.completed}
                      selected={
                        action?.deadline
                          ? moment(action?.deadline).toDate()
                          : null
                      }
                      minDate={moment(selectedGoalAction?.goal?.startline).toDate()}
                      maxDate={moment(
                        selectedGoalAction?.goal?.deadline
                      ).toDate()}
                      onChange={(date) => {
                        onChange(
                          {
                            target: {
                              value: moment(date).isValid()
                                ? moment(date).format("YYYY-MM-DD")
                                : null,
                              name: "deadline",
                            },
                          },
                          selectedGoalAction?.id,
                          action?.id,
                          selectedGoalAction?.i,
                          actionIndex
                        );
                      }}
                    />
                  </Styles.InputField>
                </Styles.ActionCol>
              </Styles.ActionsWrapper>

              <Styles.ActionsWrapper
                style={{
                  width: (isMobile || isTablet) ? "100%" : "calc(14% + 20px)",
                  paddingLeft: 10,
                }}
              >
                <Styles.ActionCol
                  $padding={isMobile || isTablet ? 1 : null}
                  $accountability
                  $flex
                  $half
                >
                  <Styles.Label $mobile>10. Accountability</Styles.Label>
                  <Styles.MainWrapper>
                    {action?.completed ? action?.accountability : (
                      <AccountabilitySelect
                        options={getGroupedOptions(accountabilities, customAccountabilities, onDeselect)}
                        disabled={action?.completed}
                        value={getOptionWithLabel(action?.accountability, accountabilities, customAccountabilities) || undefined}
                        onSelect={onSelect}
                        onCreate={onAccountabilityCreate}
                      />
                    )}
                  </Styles.MainWrapper>
                </Styles.ActionCol>
                {(isMobile || isTablet) && (
                  <Styles.ActionCol
                    $padding={isMobile || isTablet ? 1 : null}
                    $width="115px"
                    $margin={"0 10px 0 0"}
                    $halfFlex
                  >
                    <Styles.TableHeadCol>
                      <Styles.Label $mobile>Select Type</Styles.Label>
                      <Tooltip
                        placement="leftTop"
                        type="tertiary"
                        title="Set the type of action:"
                        text={
                          <>
                            <p className="mb-0">Action — an activity you do once.</p>
                            <p className="mb-0">Decision — a choice you have to make.</p>
                            <p className="mb-0">Habit — an action you do repeatedly.</p>
                          </>
                        }
                        align={{
                          offset: [0, -2]
                        }}
                        trigger="click"
                        >
                        <WarningIcon />
                      </Tooltip>
                    </Styles.TableHeadCol>
                    <Styles.Checkdiv>
                      <DropdownElement
                        getPopupContainer={(trigger) => trigger.parentElement}
                        menuItems={ActionTypes}
                        disabled={action?.completed}
                        value={action?.type || "Action"}
                        secondary
                        onChange={(value) => {
                          onChange(
                            {
                              target: {
                                value: value.key,
                                name: "type",
                              },
                            },
                            selectedGoalAction?.id,
                            action?.id,
                            selectedGoalAction?.i,
                            actionIndex
                          );
                        }}
                      >
                        <Layout.DropdownToggler
                          $empty={!action?.type}
                          $disabled={action?.completed}
                        >
                          {action?.type
                            ? action.type === "Action Item"
                              ? "Action"
                              : action.type
                            : "Select"}
                          <img src={ChevronRightIcon} alt="" />
                        </Layout.DropdownToggler>
                      </DropdownElement>
                    </Styles.Checkdiv>
                  </Styles.ActionCol>
                )}
                {isMobile && (
                  <Styles.ActionCol $width={'15px'} $margin={'15px 0 0 5px'}>
                    <Styles.InfoWrapper >
                      <Space direction="horizontal">
                        <Dropdown.Button
                          overlayClassName="row_edit"
                          trigger={"onclick"}
                          menu={{
                            items: [
                              {
                                key: "1",
                                label: action?.completed ? "Uncomplete" : "Compelete action",
                              },
                              {
                                key: "2",
                                label: "DELETE",
                                disabled: !canBeDeleted,
                              }
                            ],
                            onClick: (item) =>
                              onMenuClick(
                                selectedGoalAction?.id,
                                action?.id,
                                selectedGoalAction?.i,
                                item.key,
                                actionIndex,
                                true
                              ),
                          }}
                        ></Dropdown.Button>
                      </Space>
                    </Styles.InfoWrapper>
                  </Styles.ActionCol>
                )}
              </Styles.ActionsWrapper>
            </>
          )}

          {!isMobile && !isTablet && (
            <Styles.ActionCol $width="120px" $mr>
              <Styles.Label $mobile>Habit</Styles.Label>
              <Styles.Checkdiv>
                <DropdownElement
                  getPopupContainer={(trigger) => trigger.parentElement}
                  menuItems={ActionTypes}
                  disabled={action?.completed}
                  value={action?.type || "Action"}
                  secondary
                  onChange={(value) => {
                    onChange(
                      {
                        target: {
                          value: value.key,
                          name: "type",
                        },
                      },
                      selectedGoalAction?.id,
                      action?.id,
                      selectedGoalAction?.i,
                      actionIndex
                    );
                  }}
                >
                  <Layout.DropdownToggler
                    $empty={!action?.type}
                    $disabled={action?.completed}
                  >
                    {action?.type
                      ? action.type === "Action Item"
                        ? "Action"
                        : action.type
                      : "Select"}
                    <img src={ChevronRightIcon} alt="" />
                  </Layout.DropdownToggler>
                </DropdownElement>
              </Styles.Checkdiv>
            </Styles.ActionCol>
          )}
          {!isMobile && !isTablet && (
            <Styles.ActionCol $options>
              <Styles.InfoWrapper>
                <Tooltip
                  title={
                    action?.completed ? "Uncomplete action" : "Complete action"
                  }
                  alternative
                >
                  <Checkbox
                    id={action?.id}
                    checked={action?.completed}
                    onChange={() =>
                      onMenuClick(
                        selectedGoalAction?.id,
                        action?.id,
                        selectedGoalAction?.i,
                        "1",
                        actionIndex,
                        true
                      )
                    }
                  />
                </Tooltip>

                <Space direction="horizontal">
                  <Dropdown.Button
                    overlayClassName="row_edit"
                    trigger={"onclick"}
                    menu={{
                      items: [
                        { key: "2", label: "DELETE", disabled: !canBeDeleted },
                      ],
                      onClick: (item) =>
                        onMenuClick(
                          selectedGoalAction?.id,
                          action?.id,
                          selectedGoalAction?.i,
                          item.key,
                          actionIndex,
                          true
                        ),
                    }}
                  ></Dropdown.Button>
                </Space>
              </Styles.InfoWrapper>
            </Styles.ActionCol>
          )}
        </Styles.ActionRow>
      </Styles.ContentOne>
    );
  }
);

export const DraggableActionItem = withDraggable(ActionItem);
export default ActionItem;
