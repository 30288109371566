import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 1rem;
`;

export const AiSuggestionContianer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 0.5rem;

  .ant-switch-checked {
    background-color: #1271a6 !important;
  }
`;

export const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;

  span {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
  }
`;

export const Image = styled.img``;

export const Span = styled.span`
  color: #4cbac9;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
`;

export const ChipsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  // flex-direction: column;
  gap: 12px;
`;

export const RegGernerateButton = styled.button`
  color: #1271a6;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
`;

export const FooterHeading = styled.h2`
  margin: 0;
  padding: 0;
  color: #171717;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
`;