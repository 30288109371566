import styled from "styled-components";
import { ReactComponent as Exclamation } from "assets/images/priorities/common/exclamation.svg";

export const ProgressWrapper = styled.div`
  position: relative;
  width: 100px;
  height: 50px;
  margin-right: 50px;
  .ant-progress-inner {
    width: 144px !important;
    height: 24px !important;
    border-radius: 4px !important;
    background: #EBF2F5;
  }

  .ant-progress-text {
    position: absolute;
    width: 100%;
    top: 0;
    left: 14px;
    text-align: center;
    font-size: 14px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    font-family: Poppins;
  }

  .ant-progress-bg{
    border-radius: 0px !important;
  }
`;

export const Prioritized = styled.div`
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #737373;
`;

export const Wrapper = styled.div`
    display: flex;
    width: 100%;
    gap: 7px;
`;

export const Pointer = styled.div`

  &:hover {
    cursor: pointer;
  }
`;

export const CustomExclamation = styled(Exclamation)`
margin-top: 4px;
`;