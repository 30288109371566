import React, { useState, useEffect } from "react";

import { ReactComponent as PieChart } from "assets/images/Piechart.svg";
// import { ReactComponent as Print } from "assets/images/print-planner.svg";
import { ReactComponent as InfoIcon } from "assets/images/journeys/info.svg";
import { ReactComponent as QuestionMarkIcon } from "assets/images/priorities/help-gray.svg";

import { Toggle } from "elements";

import { useLocation } from "react-router";
import {
  APM_PATH,
  HOME_PATH,
  JOURNEY_PATH,
  WSH_PATH,
  PREMISE_PATH,
  MISSION_PATH,
  PRIORITIZE_PATH,
  MEASUREMENT_PATH,
  PURPOSE_PATH,
  ACTIONS_PATH,
  MASTER_PATH
} from "utils/routes";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { ToggleInfoSidebarAction } from "Redux/Global/action";
import PrioritizedComponent from "./component/Progress";

import useDeviceType from "../../../Hooks/ResponsiveHook";
import {
  MainWrapper,
  Container,
  Heading,
  ActionItemContainer,
  PointWrapper,
  Greetings,
  ThemeButtoncontainer,
  EmbededContent,
  InfoToggle,
  Button,
  ToggleWrapper,
  // IsProSwitchContainer,
  // SwitchWrapper,
  Progress,
  TargetIcon,
  HeaderTitleMobile,
} from "./styled";
// import useDeviceType from "../../../Hooks/ResponsiveHook";
import { toggleView } from "../../../Redux/APM/PrioritizedActions/action";
import { ReactComponent as SideInfo }  from "assets/images/journeys/sidebar-close.svg";
import { ReactComponent as Target } from "assets/images/priorities/target.svg";
import {
  ToggleSidebarAction,
} from "Redux/Global/action";
const useGreetings = () => {
  const [greeting, setGreeting] = useState("");

  useEffect(() => {
    const currentHour = moment().hour();

    if (currentHour >= 5 && currentHour < 12) {
      setGreeting("Morning");
    } else if (currentHour >= 12 && currentHour < 17) {
      setGreeting("Afternoon");
    } else if (currentHour >= 17 && currentHour < 21) {
      setGreeting("Evening");
    } else {
      setGreeting("Night");
    }
  }, []);

  return greeting;
};

const SubHeader = ({
  title,
  // isProView,
  // onViewChange,
  children,
  setIpadView,
  ipadView,
  setBackground,
}) => {
  // TODO: move to a custom hook
  const location = useLocation();
  const greeting = useGreetings();
  const dispatch = useDispatch();
  const screenSizes = useDeviceType();

  const isMobile = screenSizes === "mobile";
  const isTablet = screenSizes === "iPad";
  const { userDetail: userData } = useSelector(
    (state) => state.userDetailReducer
  );
  const { toggledSidebar } = useSelector((state) => state.globalReducer);
  const { simpleView } = useSelector((state) => state.prioritizedActions);

  const onTogglerClickHandle = () => {
    dispatch(ToggleInfoSidebarAction());
  };
  const Url = location.pathname
  const isHome = Url === HOME_PATH;
  const isWsh = Url === WSH_PATH;
  const isApm = Url === APM_PATH;
  const isProcess = Url.includes(MISSION_PATH) || Url.includes(PRIORITIZE_PATH) || Url.includes(MEASUREMENT_PATH) || Url.includes(PURPOSE_PATH) || Url.includes(ACTIONS_PATH) || Url.includes(MASTER_PATH)
  const isJourneys = Url.includes(JOURNEY_PATH + "/");

  const handleViewChange = () => {
    dispatch(toggleView());
  };
 

  const isTimeManagerIncluded = location.pathname.includes("/time-manager");

  return (
    <MainWrapper $height={isProcess ? 90 : 46}>
      <Container>
        {isMobile || isTablet ? (
          <>
          <HeaderTitleMobile>
            <Heading>{title} </Heading>
            {isJourneys && !location.pathname.includes(PREMISE_PATH) && (
              <InfoToggle title="Instructions" onClick={onTogglerClickHandle}>
                
                <InfoIcon />
              </InfoToggle>
            )}
          </HeaderTitleMobile>
          {isJourneys && <EmbededContent>{children}</EmbededContent>}

            
          </>
        ) : (
          <>
            <Heading>{title}</Heading>

            {isJourneys && <EmbededContent>{children}</EmbededContent>}

            {isJourneys && !location.pathname.includes(PREMISE_PATH) && (
              <InfoToggle title="Instructions" onClick={onTogglerClickHandle}>
                <InfoIcon />
              </InfoToggle>
            )}
          </>
        )}

        {(isHome || isWsh || isApm || isTimeManagerIncluded) && (
          <ActionItemContainer>
            <PointWrapper>
              {isHome && (
                <Greetings>
                  Good {greeting}, {userData.name}!
                </Greetings>
              )}

              {(isApm || isTimeManagerIncluded) && (
                <>
                  <ToggleWrapper>
                    <span>List View</span>
                    <Toggle
                      id={`toggle_view`}
                      checked={simpleView}
                      size="md"
                      onChange={handleViewChange}
                    />
                  </ToggleWrapper>

                  <InfoToggle
                    title="Instructions"
                    onClick={onTogglerClickHandle}
                  >
                    <QuestionMarkIcon />
                  </InfoToggle>
                </>
              )}
              {/* {isApm && (
              <IsProSwitchContainer>
                <SwitchWrapper>
                  Pro View
                  <Switch
                    size="small"
                    checked={isProView}
                    onChange={onViewChange}
                  />
                </SwitchWrapper>
              </IsProSwitchContainer>
            )} */}

              {/* <PointInputHeading>Total Return(Points)</PointInputHeading>
            <PointInput readOnly defaultValue={13} /> */}

              {/* <ThemeButtoncontainer>
                {isWsh && (
                  <Button>
                    <PieChart />
                  </Button>
                )}
                {isWsh && (
                  <>
                    <Progress>
                      <PrioritizedComponent />
                    </Progress>
                  </>
                )}
              </ThemeButtoncontainer> */}

              <TargetIcon>
                <Target
                  style={{
                    cursur: "pointer",
                  }}
                  onClick={() => {
                    setIpadView(!ipadView);
                    setBackground(true);
                  }}
                />
              </TargetIcon>
            </PointWrapper>
          </ActionItemContainer>
        )}
      </Container>
    </MainWrapper>
  );
};

export default SubHeader;
