import React, { useEffect, useRef } from "react";
import { Input } from 'antd';
import styled from "styled-components";

const EditableInput = ({ value, onChange, isEditing, placeholder, text, errorMessage }) => {
  const { TextArea } = Input;

  const $inputRef = useRef(null);

  useEffect(() => {
    if ($inputRef.current) {
      setTimeout(() => {
        const $textArea = $inputRef.current.resizableTextArea.textArea;

        $inputRef.current.focus();
        $textArea.setSelectionRange($textArea.value.length, $textArea.value.length);
      }, 50);
    }
  }, [isEditing]);

  return (
    <Container>
      {isEditing ? (
        <>
          <Content>
            <InputLabel>{text}</InputLabel>
            <TextArea
              type="text"
              value={value}
              onChange={onChange}
              disabled={!isEditing}
              placeholder={placeholder}
              autoSize
              ref={$inputRef}
            />
          </Content>
          <ErrorMessageWrapper>
            <ErrorMessage>
              {errorMessage && errorMessage}
            </ErrorMessage>
            <InputLength>{value?.length}/255</InputLength>
          </ErrorMessageWrapper>
        </>
      ) : (
        <Value>{value}</Value>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 530px;

  @media screen and (max-width: 743px) {
    width: 100%;
  }
`;

const Value = styled.div`
  font-size: 14px;
  padding: 5px;
  flex-grow: 1;
  margin-right: 10px;
`;

const Content = styled.div`
  width: 100%;
  border-bottom: 1px solid #ccc;

  @media screen and (max-width: 743px) {
    width: 95%;
    padding: 0 10px 0 10px;
  }

  .ant-input{
    border-color: none;
    outline: none !important;
  }

  .ant-input:focus, .ant-input-focused {
    border-color: transparent;
    box-shadow: none;
  }

  textarea {
    border: none;
    outline: none;
    font-size: 14px;
    padding: 5px 0px;

    ::placeholder {
      color: #A3A3A3;
      font-family: Inter;
      font-size: 14px;
      font-style: normal; 
      font-weight: 400;
      line-height: 20px;
    }
  }
`;

const InputLabel = styled.p`
  margin: 0;
  margin-bottom: 0.3rem;
  color:  #404040;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; 
`;

const ErrorMessage = styled.p`
  color: red;
  margin-top: 0.2rem;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const ErrorMessageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 32px;
`;

const InputLength = styled.p`
  color: var(--neutral-400, #a3a3a3);
  margin-top: 0.2rem;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export default EditableInput;
