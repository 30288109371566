import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { Dropdown } from "elements";
import styled from 'styled-components';

import useDeviceType from "../../../Hooks/ResponsiveHook";

import CongratsModal from "components/CongratsModal";
import StepLayout from "../StepLayout/index";
import Group from "./Group/index";

import logo from "assets/images/header_logo_modern.svg";
import SmallLogo from "assets/images/auth/logoGPS.svg";
import MagnifierIcon from "assets/svg/magnifier.svg";

import { getCookie } from "utils/StorageVariables";
import { ACTIONS_PATH } from "utils/routes";

import { RetrieveMasterPlanDetails } from "Redux/RetrieveMasterPlan/action";

import printIconOrange from "assets/images/journeys/print-icon-orange.svg";
import arrowDownIcon from "assets/images/journeys/arrow-down-icon-orange.svg";

// TODO:
// import LockedOver from "components/LockedOver/LockedOver";

import * as Styles from "./styled.js";
import styles from "./styles.module.scss";

const MasterPlan = () => {
  const $componentRef = useRef();

  const [isPrintAll, setIsPrintAll] = useState(0);

  const handlePrint = useReactToPrint({
    documentTitle: "Mission Statement",
    onAfterPrint: () => setIsPrintAll(0),
    content: () => $componentRef.current,
  });

  const [masterPlanData, setMasterPlanData] = useState();
  const [showCongratsModal, setShowCongratsModal] = useState(true);
  const [zoomedIn, setZoomedIn] = useState(false);

  const toggleZoom = () => {
    setZoomedIn(!zoomedIn);
  };

  const dispatch = useDispatch();
  const domainName = getCookie("domainName");
  // const userData = JSON.parse(getCookie("userDetail"));

  useEffect(() => {
    let MasterPlanId = getCookie("MasterPlanId");
    dispatch(RetrieveMasterPlanDetails(MasterPlanId));
  }, []);

  const { masterPlanDetails } = useSelector(
    (state) => state.RetrieveMasterPlanDetailsReducer
  );

  useEffect(() => {
    if (!masterPlanDetails?.areas) return;
    const res = SortArr(masterPlanDetails?.areas);
    setMasterPlanData(res);
  }, [masterPlanDetails?.areas]);

  useEffect(() => {
    if (isPrintAll) {
      handlePrint();
    }
  }, [isPrintAll]);

  const SortArr = (data) =>
    data?.sort(function (a, b) {
      return a?.goal?.priority - b?.goal?.priority;
    });

  const navigate = useNavigate();

  const handlePrev = () => {
    navigate(ACTIONS_PATH);
  };

  const handleNext = () => {
    navigate("/final-step");
  };

  const stepsItems = [
    {
      key: "summary",
      element: "Summary View",
    },
    {
      key: "detailed",
      element: "Detailed View",
    },
  ];

  const handlePrintClick = (step) => {
    if (step.key === "summary") {
      setIsPrintAll(1);
    } else {
      setIsPrintAll(2);
    }
  };

  const PrintDropdown = () => {
    return (
      <Dropdown
        menuItems={stepsItems}
        secondary
        overlayClassName={styles.custom}
        onChange={handlePrintClick}
      >
        <Styles.PrintToggler>
          <img src={printIconOrange} alt="" />
          Print
          <img src={arrowDownIcon} alt="" />
        </Styles.PrintToggler>
      </Dropdown>
    );
  }

  const pageStyles = printAll => {
    return `
      @page { size: portrait; }
      @page { margin-top: 24px; margin-bottom: 0; }
      @page :first { margin-top: 0; margin-bottom: 50px; }
      
      @media print {
        .extra {
          display: ` + (printAll === 2 ? "flex" : "none") + `;
        }
      }
    `;
  };

  const screenSizes = useDeviceType();

  const isMobile = screenSizes === "mobile";
  const isTablet = screenSizes === "iPad";

  return (
    <StepLayout
      title="Master Plan"
      nextPageTitle="Save" // Rank Priorities
      footerLeft={<PrintDropdown />}
      smallerFooter
      onPrev={handlePrev}
      onNext={handleNext}
    >
      <Styles.Container>
        <Styles.ContainerItem>
          <TableContainer zoomedIn={zoomedIn}>
            <Styles.TableItem ref={$componentRef}>
              <style>{pageStyles(isPrintAll)}</style>

              <Styles.TableHeader>
                <Styles.HeaderItem>
                  <Styles.Logo src={isMobile || isTablet ? SmallLogo : logo} alt="logo" />
                  <Styles.Span></Styles.Span>
                  <Styles.Heading>
                    {domainName || ""} Goal Setting by Arootah
                  </Styles.Heading>
                </Styles.HeaderItem>
                <Styles.HeaderItemRight>
                  <Styles.Title>Master Plan</Styles.Title>
                </Styles.HeaderItemRight>
              </Styles.TableHeader>

              <Styles.TableBody>
                {masterPlanData && masterPlanData?.map(item => (
                  <Group data={item} renderAll />
                ))}
              </Styles.TableBody>

              <Styles.FooterText id="footer">
                Copyright &copy; Arootah 2024. All Rights Reserved
              </Styles.FooterText>
            </Styles.TableItem>
          </TableContainer>
        </Styles.ContainerItem>
      </Styles.Container>
      <Styles.Magnifier onClick={toggleZoom}>
        <Styles.MagnifierIcon src={MagnifierIcon} />
      </Styles.Magnifier>

      <CongratsModal
        active={showCongratsModal}
        onClose={() => setShowCongratsModal(false)}
      />
    </StepLayout>
  );
};

export default MasterPlan;

export const TableContainer = styled.div`
  padding: 12px;
  width: 100%;
  height: 100%;
  overflow: auto;
  scroll-behavior: smooth;
  position: relative;

  @media screen and (max-width: 743px) {
    zoom: ${(props) => (props.zoomedIn ? 'scale(1.3)' : 'scale(1)')};
    width: ${(props) => (props.zoomedIn ? '130%' : '100%')};
    margin: 10px 0 0 0;
    overflow: scroll;
    padding: 0;
    transition: transform 0.3s ease;
  }
  
  @media print {
    overflow: visible;
    height: fit-content;
  }

  ${({ theme }) => theme.min("sm")`
    ::-webkit-scrollbar {
      width: 11px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #71aaca;
      border-radius: 10px;
      background: #71aaca;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #116595;
      border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #116595;
    }
  `}
`;
