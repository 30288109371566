import styled from "styled-components";
import SearchIcon from "assets/images/searchIcon.svg";

export const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 998;
  opacity: 0;
  visibility: hidden;
  transition: opacity .25s, visibility .25s;

  ${({ $active }) => $active && `
    opacity: 1;
    visibility: visible;
  `};
`;

export const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 342px;
  height: calc(100vh - 60px - 49px);
  background: #fff;
  right: 0;
  box-shadow: -2px 0px 10px 0px rgba(0, 0, 0, 0.25);
  z-index: 999;
  transform: translateX(100%);
  opacity: 0;
  visibility: hidden;
  transition: transform .25s, opacity .25s, visibility .25s;

  ${({ $active }) => $active && `
    transform: translateX(0%);
    opacity: 1;
    visibility: visible;
  `};

  @media screen and (max-width: 743px) {
    height: calc(100vh - 60px);
    width: 100%;
  }

  @media (min-width: 744px) and (max-width: 1256px) {
    height: calc(100vh - 60px);
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 12px 10px 8px;
  border-bottom: 1px solid var(--fill-controller-gainsboro-pressed, #CED0D4);
`;

export const HeaderCol = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
`;

export const Title = styled.span`
  color: var(--fill-button-prussian-blue, var(--Header, #112538));
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 1.68px;
  text-transform: uppercase;
`;

export const Close = styled.button`
  display: flex;
  padding: 0;
  width: 24px;
  height: 24px;
  background: transparent;
  border: none;

  svg {
    transform: rotate(0deg);
    transition: transform .3s;
  }

  :hover {
    svg {
      transform: rotate(90deg);
    }
  }
`;

export const Content = styled.div`
  background-color: #fff;
  display: flex;
  overflow-y: auto;
  height: calc(100% - 46px - 52px);
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  padding: 12px 6px 16px 16px;

  ${({ theme }) => theme.max("sm")`
    height: calc(100% - 67px - 40px);
    margin-top: calc(32px + 12px);
  `};

  ${({ theme }) => theme.min("sm")`
    ::-webkit-scrollbar {
      width: 26px;
    }

    ::-webkit-scrollbar-track {
      background: transparent !important;
    }

    ::-webkit-scrollbar-thumb {
      border: 10px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 9999px;
      background-color: #CED0D4;
    }
  `}
`;

export const Footer = styled.div`
  border-top: 1px solid #DADADA;
  background-color: #fff;
  display: flex;
`;

export const Play = styled.button`
  background-color: transparent;
  width: 100%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 10px 0;
  color: var(--Header, #112538);
  font-family: Poppins;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`;

export const Paragraph = styled.p`
  display: flex;
  flex-direction: column;
  margin-right: auto;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #06203a;
  padding: 0;
  margin-top: 0.45rem;
  margin-bottom: 0.45rem;

  ol {
    counter-reset: item;
    padding-left: 0;

    ol {
      padding-left: 1rem;
    }

    li {
      list-style-type: none;
      position: relative;
      margin-top: 12px;

      &::before {
        content: counters(item, ".") ". ";
        counter-increment: item;
      }
    }
  }
`;

export const InstructionHeading = styled.h4`
  display: flex;
  margin-right: auto;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #000000;
  padding-top: 0.5rem;
  margin-bottom: 10px;
`;

export const InstructionThumb = styled.img`
  width: 100%;
`;

export const InstructionThumbWrapper = styled.div`
  position: relative;
  /* cursor: pointer; */

  /* &::after {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    background-image: url(${SearchIcon});
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0;
    transition: opacity 0.3s;
  } */

  &:hover::after {
    opacity: 1;
  }
`;
