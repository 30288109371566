import { FC, useEffect, useState } from 'react';

import {
  Svg,
  Path,
} from './styled';

interface IProps {
  variant?: 'primary' | 'secondary';
}

const Spinner: FC<IProps> = ({ variant }) => {
  const [isAnimate, setIsAnimate] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimate(false);

      setTimeout(() => {
        setIsAnimate(true);
      }, 10);
    }, 2900);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Svg $active={isAnimate} $variant={variant || 'primary'} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 420 420" xmlSpace="preserve">
      {Array(12).fill(0).map((_, i) => <Path key={i} d="M156.6,209.2c0,0,2.3-202.9,50.7-207.2c47.8,1.8,53.8,207.5,53.8,207.5s-6.6-45.8-53.8-45.6 C166,163.7,156.6,209.2,156.6,209.2z"/>)}
    </Svg>
  );
}

export default Spinner;
