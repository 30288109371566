import styled, { css } from "styled-components";

import { Avatar } from "antd";
import Button from "components/Button";

export const Container = styled.div`
  width: 100%;
`;

export const FooterSection = styled.div`
  width: 100%;
  @media (min-width: 744px) and (max-width: 1024px) {
    display: flex;
    margin-left: 35px;
    margin-top: -10px;
  }
`;

export const TableHeadCol = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  color: var(--neutral-500, var(--Input-header, #737373));
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;

  ${({ $type }) =>
    $type &&
    $type === "goal" &&
    css`
      width: 40%;
      margin-left: 12px;
      padding-left: 34px;
      @media screen and (min-width: 1025px) {
        padding-left: 0;
      }
    `}

  ${({ $type }) =>
    $type &&
    $type === "legacy" &&
    css`
      text-align: right;
      padding-right: 32px;
      flex: 1;
      // min-width: 160px;
    `}

  ${({ $type }) =>
    $type &&
    $type === "measurement" &&
    css`
      width: 12%;
      min-width: 200px;
    `}

  ${({ $type }) =>
    $type &&
    $type === "gap" &&
    css`
      width: 8%;
      padding-right: 12px;
      min-width: 100px;
    `}

  ${({ $type }) =>
    $type &&
    $type === "gap-percent" &&
    css`
      width: 4%;
      min-width: 90px;
    `}

  ${({ $type }) =>
    $type &&
    $type === "frequency" &&
    css`
      min-width: 90px;
    `}
`;

export const Heading = styled.h1`
  color: #06203a;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  vertical-align: middle;
  font-size: 18px;
  line-height: 24px;
  margin: 0;

  ${({ theme }) => theme.max("sm")`
    font-size: 14px;
  `}
`;

export const ModifiedButton = styled(Button)`
  background: linear-gradient(161.19deg, #1263de 18.35%, #448fff 74.45%);
`;

export const NextButtonWrapper = styled.div`
  display: flex;
  margin-left: auto;
`;

export const ContainerItem = styled.div`
  width: 100%;
  height: calc(100% - 67px - 59px - 46px);
`;

export const TableContainer = styled.div`
  padding: 10px 0 10px 10px;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 1025px) {
    margin-bottom: 100px;
  }
`;

export const Row = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  border-left: ${({ border }) => `4px solid ${border}`};
  margin-bottom: 4px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.17);
  min-height: 50px;
  position: relative;
  padding: 5px 16px 5px 12px;

  @media screen and (max-width: 743px) {
    height: initial;
    align-items: flex-start;
    padding: 7px 7px 7px 0;
  }
  @media (min-width: 744px) and (max-width: 1024px) {
    width: 98%;
  }

`;

export const RowContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const LeftSection = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 40%;
  flex-direction: row;

  @media screen and (max-width: 1024px) {
    width: 100%;
    margin-left: 5px;
  }
`;

export const Text = styled.p`
  margin: 0;
  font-family: "Inter";
  font-size: 16px;
  line-height: 18px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ $title }) =>
    $title &&
    css`
      font-size: 12px;
      line-height: 24px;
      font-weight: 600;
      color: #737373;
      margin-top: 20px;
      text-transform: uppercase;

      @media (min-width: 744px) and (max-width: 1024px) {
        margin-top: 1px;
      }
    `}

  ${({ $ml }) =>
    $ml &&
    css`
      margin-left: ${$ml}px;
    `}

  ${({ $mt }) =>
    $mt &&
    css`
      margin-top: ${$mt}px;
    `}

  ${({ $area }) =>
    $area &&
    css`
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
      color: #525252;
    `}

  ${({ $category }) =>
    $category &&
    css`
      font-size: 12px;
      line-height: 20px;
      font-weight: 600;
      color: #171717;

      @media screen and (max-width: 743px) {
        line-height: 10px;
      }
      @media (min-width: 744px) and (max-width: 1024px) {
        line-height: 20px;
        margin-left: 3px;
      }
    `}
`;

export const Label = styled.label`
  display: none;

  @media screen and (max-width: 743px) {
    display: block;
    font-family: "Inter";
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    font-weight: 600;
    margin-bottom: 0;

    span {
      font-weight: 400;
    }
  }
`;

export const MidSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 12%;
  min-width: 200px;

  @media screen and (max-width: 743px) {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
  @media (min-width: 744px) and (max-width: 1024px) {
    width: 30%;
    flex-direction: column;
    align-items: flex-start;

  }
`;

export const OldMeasurement = styled.span`
  color: var(--neutral-500, var(--Input-header, #737373));
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  display: inline-block;
`;

export const PopoverToggle = styled.div`
  display: flex;
  // width: 100%;
  width: 172px;
  height: 36px;
  padding: 0;
  border: none;
  background: none;

  @media screen and (max-width: 1025px) {
    width: 100%;
    height: 36px;
    padding: 0;
    margin-left: 20px;
  }
`;

export const Input = styled.input`
  width: 90%;
  font-family: "Inter";
  color: #000000;
  padding: 0 12px;
  border: 1px solid #f3f4f5;
  border-radius: 4px;
  height: 36px;
  line-height: 36px;
  font-size: 14px;

  &:focus {
    outline: none !important;
  }

  &::placeholder {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #8e97a3;
  }
`;

export const HeaderText = styled.h3`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  margin: 0;
  /* padding-left: ${({ isRight }) => isRight && "5rem"}; */
  padding-right: ${({ isRight }) => isRight && "1.25rem"};

  ${({ theme }) => theme.max("sm")`
    padding-left: 0rem;
  `}
`;

export const WrapAvatar = styled.div`
  width: 24px;
  margin-right: 12px;

  @media screen and (max-width: 743px) {
    padding: 8px 0 0 4px;
  }
`;

export const IdAvatar = styled(Avatar)`
  background-color: #e7f1f6 !important;

  .ant-avatar-string {
    color: #1193ea !important;
    font-family: "Inter" !important ;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    vertical-align: middle !important;
  }
`;

export const WrapHead = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  flex: 1;
  padding-right: 4%;

  @media screen and (max-width: 743px) {
    margin-left: 2px;
  }
`;

export const WrapText = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  flex-direction: row;

  @media screen and (max-width: 743px) {
    flex-direction: column;
  }

  @media (min-width: 744px) and (max-width: 1024px) {
    flex-direction: row;
  }
`;

export const HeaderFirstSection = styled.div`
  width: calc(40px + 15px + 74px);
  padding-left: 40px;
  ${({ theme }) => theme.max("sm")`
    width: calc(40px + 50px + 25px);
  `}
`;

export const HeaderLeftSection = styled.div`
  width: 25.5%;
  display: flex;
  min-width: 170px;

  ${({ theme }) => theme.max("xl")`
    min-width: 170px;
    width: 28%;
  `}
  ${({ theme }) => theme.max("lg")`
    min-width: 170px;
    width: 27%;
  `}
  ${({ theme }) => theme.max("md")`
    min-width: 130px;
    width: 25.5%;
  `}

  ${({ theme }) => theme.max("sm")`
    min-width: 100px;
    width: 17.5%;
    padding-left: 1rem;
  `} /* @media screen and (max-width: 1439px) {
    transform: translateX(10px);
  } */
`;

export const HeaderMidSection = styled.div`
  width: 35%;
  display: flex;
  min-width: 170px;
`;

export const HeaderRightSection = styled.div`
  width: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const OldSection = styled.div`
  text-align: right;
  padding-right: 32px;
  padding-right: 32px;
  flex: 1;
  // min-width: 180px;
`;

export const GapSection = styled.div`
  width: 8%;
  padding-right: 12px;
  min-width: 100px;

  @media (min-width: 744px) and (max-width: 1024px) {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  @media screen and (max-width: 743px) {
    margin-top: 10px;
  }
`;

export const GapPercentSection = styled.div`
  width: 4%;
  min-width: 90px;

  @media (min-width: 744px) and (max-width: 1024px) {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  @media screen and (max-width: 743px) {
    margin-top: 10px;
    margin-left: 5px;
  }
`;

export const FrequencySection = styled.div`
  min-width: 90px;
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: 743px) {
    justify-content: center;
    margin-top: 10px;
  }
  
  @media (min-width: 744px) and (max-width: 1024px) {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-left: 30px;
  }
`;

export const FrequencyToggler = styled.div`
  display: flex;
  gap: 7px;
  color: var(--neutral-500, var(--Input-header, #737373));
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;

  svg {
    transform: rotate(90deg);
  }

  @media screen and (max-width: 743px) {
    svg {
      transform: rotate(0deg);
      margin-top: 4px;
    }
    
  }
`;

export const GapText = styled.p`
  color: var(--neutral-900, var(--Color-2, #171717));
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;

  span {
    color: var(--neutral-600, var(--Color-2, #525252));
  }
`;

export const BtnWrapper = styled.div`
  margin-left: 16px;
`;

export const SwitchWrapper = styled.div`
  font-size: 0.75rem;
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  /* padding-top: 0.25rem; */
  padding-left: 6px;

  ${({ theme }) => theme.max("sm")`
  display:none !important;
  `}
  ${({ theme }) => theme.max("xs")`
  display:none !important;
  `}
`;

export const SwitchWrapperMobile = styled.div`
  display: none !important;
  @media only screen and (max-width: 743px) and (min-width: 450px) {
    display: flex !important;
    align-items: center;
    position: absolute;
    left: 10px;
    bottom: 5px;
  }
  ${({ theme }) => theme.max("xs")`
  display:flex !important;
   align-items: center;
 position:absolute;
 right:6px;
 top:5px;
  `}
`;

export const TextPvt = styled.h3`
  display: none;
  margin: 0;
  padding: 0;
  padding-right: 6px;
  ${({ theme }) => theme.max("sm")`
  display:block ;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: #000000;
  `}
  ${({ theme }) => theme.max("xs")`
  display:block ;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: #000000;
  `}
`;

export const BottomSection = styled.div`
  width: 100%;
  height: 50px;
  flex-direction: row;
  display: flex;
  padding: 0 8px 0 8px;
  margin-top: 15px;

  @media (min-width: 744px) and (max-width: 1024px) {
    height: 100px;
    margin-top: 0px;
    padding: 0 ;
    
  }

  @media screen and (max-width: 743px) {
    ${({ $padding }) =>
    $padding &&
      css`
      padding: 0 8px 0 18px;
    `}
    margin-bottom: 10px;
  }
`;

export const Box = styled.div`
  width : 30%;
  @media (min-width: 744px) and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  ${({ $width }) =>
    $width &&
    css`
      width: ${$width};
    `}
`;
