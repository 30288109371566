import styled from "styled-components";

export const Wrapper = styled.div<{ $active: boolean; }>`
  padding: 6px 10px;
  border-radius: 3px;
  background: var(--Color-4, #F5F5F5);
  cursor: pointer;
  user-select: none;

  ${({ $active }) => $active && `
    background: var(--primary-500-main, #1271A6);

    ${Title} {
      color: var(--neutral-0, #FFFFFF);
      font-weight: 500;
    }
  `}
`;

export const Title = styled.span`
  color: var(--Text, #525252);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  line-height: 20px;
`;
