import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: calc(100% - 43px);

  @media screen and (max-width: 768px) {
    height: auto;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    height: auto;
  }

`;

export const Step = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 32px 9px 22px;
  background-color: #fff;
  border-bottom: 1px solid #C1CAD1;
`;

export const Heading = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 365px;
`;

export const Toggler = styled.button`
  display: flex;
  width: 24px;
  height: 24px;
  background: transparent;
  padding: 0;
  border: none;

  transform: rotate(180deg);
  transition: all .3s;

  ${({ $active }) => $active && `
    transform: rotate(0deg);
  `};
`;

export const ActionItemWrapper = styled.div`
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  margin-top: 5px;
  border-radius: 5px 0 0px 5px;
  border-left: 3px solid;
  ${({ $color }) => $color && css`
    border-top-color: ${$color};
  `}
`;

export const ActionItemHeader = styled.div`
  display: flex;
  border-bottom: 1px solid lightgray;
  padding-bottom: 10px;
`;
export const ActionHeading = styled.h3`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  margin: 0;
  height: 100%;
  min-width: 21px;
  margin-right: 7px;
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 12px 4px 12px 12px;
  gap: 7px;

  @media screen and (max-width: 768px) {
    width: 97%;
  }
  @media screen and (max-width: 400px) {
    ${({ $swidth }) => $swidth && css`
      width: 90%;
    `}
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 99%;
  }

`;

export const Step1 = styled.div`
  width: 100%;
  width: ${({ isWidth }) => isWidth && "100%"};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 4px 0;
  margin: ${({ isMargin }) => isMargin && "0"};

  ${({ theme }) => theme.max("sm")`
    display: none;
  `}
`;

export const GoalsHeader = styled.div`
  
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
`;

export const WrapHead = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: calc(28% + 80px);
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const GoalHeading = styled.span`
  color: var(--Neutral-900, var(--Color-2, #171717));
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;

  @media (min-width: 768px) and (max-width: 1024px) {
    ${({ $width }) => $width && css`
      width: ${$width};
  `}
  }
  @media screen and (max-width: 768px) {
    ${({ $width }) => $width && css`
      width: ${$width};
  `}
  }
`;

export const GoalText = styled.span`
  color: var(--Neutral-900, var(--Color-2, #171717));
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

export const InputLabel = styled.label`
  color: var(--Input-header, #737373);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 9px;

  @media screen and (max-width: 768px) {
    ${({ $nonstyle }) =>
    $nonstyle && `
      line-height: 12px;
      margin-bottom: 0;
      font-size: 13px;
      padding-top: 15px;
    `}
      
  }
`;

export const Planwrapper = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 12px;
  border-radius: 0 0 5px 5px;
`;

export const Item = styled.span`
  color: var(--Input-header, #737373);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  width: 100%;

  ${({ $type }) => $type && css`
    display: flex;
    align-items: center;
    gap: 6px;
  `}
`;

export const Goalsitem = styled.div`
  width: 100%;
`;

export const GoalsRow = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 0 4px 0 0;

  ${({ theme }) => theme.max("sm")`
    flex-direction: column;
    padding: 0 4px 4px;
  `}
  @media (min-width: 768px) and (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const GoalsCol = styled.div`
  ${({ $goal }) =>
    $goal && `
    width: 90px;
  `}

  ${({ $accountabilitySmall }) => $accountabilitySmall && `
    @media screen and (max-width: 768px) {
      margin: 20px 0 0 0px;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
      width: 25%;
      margin-left: 30px;
    }
  `}
  ${({ $accountability }) => $accountability && `
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    width: 14%;
    margin-right: 20px;
  `}

  ${({ $type }) => $type && `
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    width: 14%;
    margin-left: 25px;

    @media (min-width: 768px) and (max-width: 1024px) {
      width: 50%;
      justify-content: center;
      align-items: center;
    }
    @media screen and (max-width: 768px) {
      margin: 20px 0 0 10px;
    }
  
  `}

  ${({ theme }) => theme.max("sm")`
    width: 100%;
  `}

  ${({ $name }) =>
    $name &&
    `
    flex: 1;
    padding-left: 17px;
    padding-right: 3px;
  `}

  ${({ $date }) => $date && `
    width: 7vw;
    display: flex;
    flex-direction: column;
    @media (min-width: 768px) and (max-width: 1024px) {
      width: 50%;
    }

    @media screen and (max-width: 768px) {
      width: 50%;
    }
  
  `}

  ${({ $dropdown }) =>
    $dropdown &&
    `
    width: 180px;
    margin-left: 6px;
    padding-bottom: 8px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `}

  ${({ $private }) => $private && `
    width: 195px;
  `}

  ${({ $habit }) => $habit && `
    display: flex;
    align-items: center;
    // width: calc(140px + 51px);
    gap: 11px;

    .ant-btn-compact-first-item {
      display: none;
    }

    .ant-btn {
      background-color: transparent;
      border: none;
      transform: rotate(90deg);
    }
  `}

  ${({ $dot }) =>
    $dot &&
    `
    width: 61px;
    display:flex;
    justify-content: center ;
    // align-items: center;
    margin-top:0.28rem;
    float:right;
    `}

  ${({ theme }) => theme.max("sm")`
    ${({ $name }) =>
      $name &&
      `
      padding: 10px 8px;
    `}

    ${({ $date }) =>
      $date &&
      `
      margin-left: 0;

      &:first-of-type {
        margin-right: 3.5px;
      }

      &:last-of-type {
        margin-left: 3.5px;
      }
    `}

    ${({ $dropdown }) =>
      $dropdown &&
      `
      margin-left: 0;
      padding: 0 7px 5px 7px;
      background: #E28F21;
    `}
  `}
`;

export const PrivateLabel = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 2px 6px;
  border-radius: 24px;
  background: var(--Primary-50, #ECF3F6);
  color: var(--primary-500-main, #1271A6);
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
`;
export const Label = styled.label`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #262626;
  margin-bottom: 2px;
  `

  export const InputField = styled.div`
  width:100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      position: relative;

      input {
        width: 100%;
        font-family: "Inter";
        font-weight: 400;
        min-width: 98px;
        font-size: 12px;
        line-height: 20px;
        color: #1b2a3d;
        border: 1px solid #e3e5e9;
        border-radius: 4px;
        padding: 3px 6px;
        outline: none;
        transition: box-shadow .3s;

        &::placeholder {
          color: #A3A3A3;
        }

        &:focus,
        &:active {
          box-shadow: 0px 0px 0px 2px #D0E3ED;
        }
      }

      
    }
  }
`;

export const ActionName = styled.span`
  width: 100%;
  color: var(--stroke-text-body-prussian-blue, #1B2A3D);
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
`;

export const Wrapper = styled.div`
  width: 100%;

  @media screen and (max-width: 768px) {
    // margin-top: -15px;
  }
`;

export const Content = styled.div`
  height: 100%;
  margin-bottom: 4px;
  border-bottom: 1px solid #D9D9D9;
  padding: 0 0 12px 0;
  @media (min-width: 768px) and (max-width: 1024px) {
    border-bottom: 0 solid #D9D9D9;
  }
  @media screen and (max-width: 768px) {
    border-bottom: 0 solid #D9D9D9;
  }

`;

export const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 4px 4px 0;
`;

export const HeadCol = styled.div`
  // border: 1px solid red;

  ${({ $actions }) => $actions && css`
    display: flex;
    align-items: center;
  `}

  ${({ $width }) => $width && css`
    width: ${$width};
  `}

  ${({ $flex }) => $flex && css`
    flex: 1;
  `}

  ${({ $dnd }) => $dnd && css`
    // margin-right: 3.8vw;
    // padding-right: 30px;
    // margin-right: 30px;
  `}

  ${({ $deadline }) => $deadline && css`
    display: flex;
    align-items: center;
    gap: 12px;
    margin-right: 2vw;
  `}

  ${({ $startline }) => $startline && css`
    margin-right: 2vw;
  `}

  ${({ $last }) => $last && css`
    ::before {
      content: "";
    }
  `}
`;

export const ActionsWrappers = styled.div`
  width: 80%;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    width: 100%;
  }
 
`
export const ActionsWrapper = styled.div`
  display: flex;

  ${({ $first }) => $first && `
    width: calc(28% + 80px);
    // padding-right: 30px;
  `}

  ${({ $action }) => $action && `
    gap: 12px;
    // margin-right: 2vw;
  `}

  ${({ $fill }) =>
    $fill &&
    `
    flex: 1;
  `}

  @media (min-width: 768px) and (max-width: 1024px) {
    width: 50%;

  }
`;

export const InputValue = styled.span`
  color: var(--Neutral-900, var(--Color-2, #171717));
  font-family: Inter;
  font-size: 12px;
  line-height: 20px;

  @media screen and (max-width: 768px) {
    line-height: 15px;
  }

`;

export const Placeholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 385px) {
    width: 85%;
    margin-top: 45%;
  }

  @media (min-width: 386px) and (max-width: 400px) {
    width: 90%;
    margin-top: 50%;
  }

  @media (min-width: 401px) and (max-width: 767px) {
    margin-top: 55%;
  }
  
  @media screen and (max-width: 767px) {
    padding: 0 40px 0 40px;
    text-align: center;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    margin-top: 40%;
    padding: 0 20px 0 20px;
  }

`;

export const PContainer = styled.div`
  max-width: 590px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

export const PTitle = styled.span`
  color: var(--Text-Body-Light-state-gray, #798B99);
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
`;

export const PText = styled.span`
  color: var(--Text-Body-Light-state-gray, #798B99);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  line-height: 20px;
`;
