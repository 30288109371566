import { useState, FC } from "react";
import { Select } from "antd";

import CustomOption from "./CustomOption";
import CreateCustomAccountability from './CreateCustomAccountability';

import { Wrapper } from "./styled";
import { IAccountability } from "Redux/ListAccountability/types";

interface IProps {
  text: string;
  options: any;
  error: string;
  value: any;
  isWidth?: boolean;
  disabled?: boolean;
  onSelect: (value: any) => void;
  onCreate: (value: string, callback: (success: any) => void) => void;
}

export const getGroupedOptions = (options: IAccountability[], customOptions: IAccountability[], onDelete: (value: string) => void) => {
  const optionsFormatted = options
    .map(i => ({
      value: i.id,
      label: i.name
    }));

  const customOptionsFormatted = customOptions
    .map(i => ({
      value: i.id,
      label: <CustomOption value={i.id} label={i.name} onDelete={onDelete} />,
      originalLabel: i.name
    }));

  return [
    {
      label: "",
      options: [...optionsFormatted],
    },
    {
      label: customOptions.length > 0 ? "Custom Accountabilities" : "",
      options: [...customOptionsFormatted],
    },
  ];
};

export const getOptionWithLabel = (value: string, options: IAccountability[], customOptions: IAccountability[]) => {
  const allOptions = [...options, ...customOptions];
  const option = allOptions.find(i => i.id === value) || allOptions.find(i => i.name === value);

  if (!option) {
    return undefined;
  }

  return {
    value: option?.id,
    label: option?.name
  };
}

const AccountabilitySelect: FC<IProps> = ({ text, options, error, disabled, onSelect, onCreate, value, isWidth, ...rest }) => {
  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (value: string) => {
    setSearchValue(value);
  }

  const handleCreate = (value: string, callback: (success: any) => void) => {
    onCreate(value, callback);
    setSearchValue("");
  }

  const handleSelect = (value: any) => {
    onSelect(value);
    setSearchValue("");
  }

  return (
    <Wrapper $error={!!error}>
      <Select
        {...rest}
        getPopupContainer={(trigger) => trigger.parentNode.parentNode}
        value={value}
        showSearch
        searchValue={searchValue}
        onSelect={handleSelect}
        style={{
          width: "100%"
        }}
        optionFilterProp="value"
        placeholder="Select"
        autoClearSearchValue={false}
        filterOption={(input, option) =>
          ((typeof option?.label === 'string' ? option.label : option?.originalLabel).toLowerCase() ?? "").includes(input.toLowerCase())
        }
        filterSort={(optionA, optionB) => {
          const labelA = typeof optionA?.label === 'string' ? optionA.label.toLowerCase() : "";
          const labelB = typeof optionB?.label === 'string' ? optionB.label.toLowerCase() : "";

          return labelA.localeCompare(labelB);
        }}
        labelInValue
        listHeight={200}
        dropdownRender={(menu) => {
          return (
            <>
              {menu}
              <CreateCustomAccountability
                onCreate={handleCreate}
                onSelect={handleSelect}
              />
            </>
          )
        }}
        options={options}
        disabled={disabled}
        onSearch={handleSearch}
        placement="bottomRight"
      />
    </Wrapper>
  );
};

export default AccountabilitySelect;
