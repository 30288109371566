import React from "react";
import { Select } from "antd";
import styled from "styled-components";
import { ReactComponent as Arrow } from "./Chevron-Down.svg";
import { ReactComponent as Edit } from "./Edit.svg";


const SelectStatment = ({ text, options, onChange, value, isWidth }) => {
  return (
    <Container>
      <Content>
        <Label>{text}</Label>
        <div className="stat_select">
          <Select
            popupClassName="statment_select"
            suffixIcon={<Edit />}
            value={value}
            placement={"bottomRight"}
            style={{
              marginRight: '1rem'
            }}
            dropdownStyle={{
              minWidth: "160px",
            }}
            showSearch={false}
            onChange={onChange}

            placeholder="All Steps"
            optionFilterProp="value"
            // filterOption={(input, option) =>
            //   (option?.label ?? "").includes(input)
            // }
            // filterSort={(optionA, optionB) =>
            //   (optionA?.label ?? "")
            //     .toLowerCase()
            //     .localeCompare((optionB?.label ?? "").toLowerCase())
            // }
            options={options}
          />
        </div>
      </Content>
    </Container>
  );
};

export default SelectStatment;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
 display: flex;
  justify-content: center;
  align-items: center;
`;

const Label = styled.h3`
  margin: 0;
  margin-bottom: 0.2rem;
  color: #737373;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`;
