import React from 'react';
import { useEffect, useState, useRef } from 'react';
import OtpInput from 'react-otp-input';
import { InputField, VeriyButton } from '../../../elements';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
// @ts-ignore
import { getCookie, setCookie, removeCookie } from "../../../utils/StorageVariables"
import { WELCOME_PATH } from '../../../utils/routes';
// @ts-ignore
import { ReactComponent as DefaultLogo } from "assets/svg/logo-arootah.svg";
// @ts-ignore
import { VerifyOtp } from '../../../Redux/VerifyOtp/action';
// @ts-ignore
import { RequestOtp } from '../../../Redux/RequestOtp/action';

import styled from "styled-components";
import { Spin } from "antd";


// @ts-ignore
import * as Layout from '../styled';

import {
  Form,
  Title,
  Text,
  FormWrapper,
  Helper
} from './styled';

const increaseBy = (num: number) => {
  let counter = num;

  return () => {
    const result = counter;
    counter += 50;
    return result;
  }
}

const StyledSpin = styled(Spin)`
position: fixed;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
`;
interface VerificationProps {
  active?: any;
}
const Verification: React.FC<VerificationProps> = ({ active }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  // @ts-ignore
   // Extract loading states from both reducers
   const { loading: verifyLoading, error, success } = useSelector(state => state.verifyOtpReducer);
   // @ts-ignore
   const { loading: requestLoading } = useSelector(state => state.requestOtpReducer);
 
   // Combine loading states
   const isLoading = verifyLoading || requestLoading;

  const animateDelayCounter = increaseBy(100);
  const $button = useRef<HTMLButtonElement>(null);

  const [animate, setAnimate] = useState(false);
  const [otp, setOtp] = useState('');
  const [timer, setTimer] = useState<number | null>(null);  // Send Timer state
  const [timeLeft, setTimeLeft] = useState<number>(0);  // Send Time left for the timer
  const [verifyTimer, setVerifyTimer] = useState<number | null>(null);  // Verify Timer state
  const [verifyTimeLeft, setVerifyTimeLeft] = useState<number>(0);  //Verify Time left for the timer
  const [attempts, setAttempts] = useState<number>(0);


  const USER_DETAIL = "userDetail"

  let data = JSON.parse(getCookie("userDetail"));
  useEffect(() => {
    setTimeout(() => {
      setAnimate(true);
    }, 100);
  }, []);

  useEffect(() => {
    // Clear Interval for send Again button.
    if (timer) {
      const interval = setInterval(() => {
        setTimeLeft(prevTime => {
          if (prevTime <= 1) {
            clearInterval(interval);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [timer]);

  useEffect(() => {
    // Clear Interval for Verify button.
    if (verifyTimer) {
      const interval = setInterval(() => {
        setTimeLeft(prevTime => {
          if (prevTime <= 1) {
            clearInterval(interval);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [verifyTimer]);

  useEffect(() => {
    // Set TimeLeft state for send again after refresh the page.
    const savedTimerStart = getCookie('sendTimerStart');
    const savedTimeLeft = getCookie('sendTimeLeft');

    if (savedTimerStart && savedTimeLeft) {
      const timerStart = parseInt(savedTimerStart, 10);
      const savedTime = parseInt(savedTimeLeft, 10);
      const elapsed = Math.floor((Date.now() - timerStart) / 1000);
      const remainingTime = Math.max(savedTime - elapsed, 0);
      setTimeLeft(remainingTime);
    }
  }, []);

  useEffect(() => {
    // Set TimeLeft state for verify after refresh the page.
    const savedTimerStart = getCookie('verifyTimerStart');
    const savedTimeLeft = getCookie('verifyTimeLeftBehind');

    if (savedTimerStart && savedTimeLeft) {
      const timerStart = parseInt(savedTimerStart, 10);
      const savedTime = parseInt(savedTimeLeft, 10);
      const elapsed = Math.floor((Date.now() - timerStart) / 1000);
      const remainingTime = Math.max(savedTime - elapsed, 0);
      setVerifyTimeLeft(remainingTime);
    }
  }, []);


  useEffect(() => {

    if (timeLeft > 0) {
      // Clear local storage while send again time is over.
      const interval = setInterval(() => {
        setTimeLeft(prevTime => {
          if (prevTime <= 1) {
            clearInterval(interval);
            removeCookie('timerStart');
            removeCookie('timeLeft');
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [timeLeft]);

  useEffect(() => {
    // Clear local storage while verify time is over.
    if (verifyTimeLeft > 0) {
      const interval = setInterval(() => {
        setVerifyTimeLeft(prevTime => {
          if (prevTime <= 1) {
            clearInterval(interval);
            removeCookie('verifytTimerStart');
            removeCookie('verifyTimeLeftBehind');
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [verifyTimeLeft]);

  useEffect(() => {
    setAttempts(0)
  }, [verifyTimeLeft === 0])



  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    // Check if 'otp' parameter exists
    if (queryParams.has('otp')) {
      const otpParam = queryParams.get('otp');
      const payloadData = {
        email: data?.email,
        otp: otpParam
      }
      dispatch(
        VerifyOtp(
          payloadData,
          navigateHandle
        )
      )

    }
  }, []);


  useEffect(() => {
    if (success) {
      // Clear error when success is true
      dispatch({ type: 'OTP_FAIL' }); //
    }
  }, [success, dispatch]);

  useEffect(() => {
    if (otp.length === 6 && $button.current) {
      $button.current.focus();
    }
  }, [otp]);

  if (!active)
    return null;



  const handleChange = (value: string) => {
    if (!(/^\d{0,6}$/.test(value) || value === '')) {
      return;
    }
    setOtp(value);
  }


  const navigateHandle = () => {
    navigate(WELCOME_PATH, { replace: true });
  }

  const verifyOtpFunction = () => {
    if (attempts < 3) {
      const payloadData = {
        email: data?.email,
        otp
      }
      dispatch(
        VerifyOtp(
          payloadData,
          navigateHandle
        )
      )
      setAttempts(attempts + 1)
    } else {
      const now = Date.now();
      const newTimeLeft = 120;  // 2 minutes = 120 seconds
      setCookie('verifyTimerStart', now.toString());
      setCookie('verifyTimeLeftBehind', newTimeLeft.toString());
      setVerifyTimeLeft(newTimeLeft);

    }

  }


  const resendOtpFunction = () => {
    const paylaod = {
      email: data?.email
    }
    dispatch(RequestOtp(paylaod))
    const now = Date.now();
    const newTimeLeft = 120;  // 2 minutes = 120 seconds
    setCookie('sendTimerStart', now.toString());
    setCookie('sendTimeLeft', newTimeLeft.toString());
    setTimeLeft(newTimeLeft);

  }

  if (isLoading) {
    return <StyledSpin />
  }

  return (
    <Layout.Screen $landing>
      <FormWrapper style={{ textAlign: "center" }}>
        <DefaultLogo width={143} height={56} $animate={animate} $animateDelay={animateDelayCounter()} />

        <Title $animate={animate} $animateDelay={animateDelayCounter()} data-testid="title">Verify your email</Title>
        <Text $animate={animate} $animateDelay={animateDelayCounter()} data-testid="text">Enter the code emailed to you to verify your email. The code will expire in 24 hours.</Text>
        {/* <span style={{color: "red", fontSize: "12px", marginBottom:"18px"}}>{error  && error}</span> */}
        <Form $animate={animate} $animateDelay={animateDelayCounter()}
        >
          <OtpInput
            value={otp}
            onChange={handleChange}
            numInputs={6}
            // @ts-ignore
            renderInput={(props) => <InputField {...props} style={{ width: 44, height: 44, fontSize: 18, fontWeight: 600, textAlign: 'center', borderColor: error ? "red" : "null" }} />}
            containerStyle={{ gap: 12 }}
            inputType="tel"
            data-testid="otp-input"
          />
          <span style={{ color: "red", fontSize: "12px", marginBottom: "18px" }}>{verifyTimeLeft > 0 ? "You've reached the maximum attempts. Try again in 2 minutes." : error && error}</span>

          <VeriyButton
            style={{ marginTop: 16, width: '100%' }}
            disabled={otp.length < 6 || verifyTimeLeft > 0}
            ref={$button}
            data-testid="verify-button"
            onClick={() => {
              if (otp?.length == 6) {
                verifyOtpFunction()
              }
            }}
          >
            {verifyTimeLeft > 0 ? `Verify again in ${Math.floor(verifyTimeLeft / 60)}:${('0' + (verifyTimeLeft % 60)).slice(-2)}` : 'Verify'}
          </VeriyButton>
        </Form>

        <Helper $animate={animate} $animateDelay={animateDelayCounter()}>
          Didn’t receive a code?{' '}
          <VeriyButton
            style={{ fontWeight: '700', fontSize: 14 }}
            styleType="link-primary"
            data-testid="resend-button"
            onClick={() => resendOtpFunction()}
            disabled={timeLeft > 0}
          >{timeLeft > 0 ? `Send again in ${Math.floor(timeLeft / 60)}:${('0' + (timeLeft % 60)).slice(-2)}` : 'Send again'}</VeriyButton>
        </Helper>
      </FormWrapper>
    </Layout.Screen>
  );
}

export default Verification;
