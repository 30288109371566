import React, {
  ChangeEvent,
  FC,
  forwardRef,
  useImperativeHandle,
  ForwardRefRenderFunction,
  SyntheticEvent,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button, InputArea, Tooltip as ElementsTooltip } from "elements";
import Cookies from "js-cookie";
import { InputAreaProps } from "elements/InputArea";

import Assistant, { AssistantProps } from "./components/Assistant";
import Tooltip from "./components/Tooltip";

// @ts-ignore
import assistantIcon from "assets/images/assistant.svg";

import * as Styled from "./styled";

import { DataType, Item } from "./components/Assistant/data";

type ExtendedInputAreaProps = Pick<InputAreaProps, Exclude<keyof InputAreaProps, "onChange" | "onBlur">> &
  AssistantProps & {
    id: string;
    name?: string;
    tooltip?: boolean;
    containerRef?: React.RefObject<HTMLDivElement>;
    onlyButton?: boolean;
    onChange: (value: string, field: string, ai?: boolean) => void;
    onBlur: (value: string, field: string) => void;
    onOpen?: (isOpen: boolean) => void;
  };

interface InputMethods {
  focus: () => void;
}

const AssistantContext = createContext<{
  currentOpen: string | null;
  setCurrentOpen: (id: string | null) => void;
} | null>(null);

export const AssistantProvider: FC<{ children: any }> = ({ children }) => {
  const [currentOpen, setCurrentOpen] = useState<string | null>(null);
  const value = { currentOpen, setCurrentOpen };

  return (
    <AssistantContext.Provider value={value}>
      {children}
    </AssistantContext.Provider>
  );
};

export const useAssistant = () => {
  const context = useContext(AssistantContext);

  if (context === null) {
    throw new Error("useAssistant must be used within an AssistantProvider");
  }

  return context;
};

const USE_AI_ASSISTANT = true;

const InputAreaAI: ForwardRefRenderFunction<
  InputMethods,
  ExtendedInputAreaProps
> = (
  {
    id,
    value: initialValue,
    placeholder,
    max,
    tooltip,
    containerRef,
    onChange,
    onBlur,
    onOpen,
    assistant,
    onlyButton,
    size,
    ...props
  },
  ref
) => {
  const [value, setValue] = useState(initialValue);
  const [isTooltip, setIsTooltip] = useState(false);
  const [isVisible/* , setIsVisible */] = useState(true);

  const handleTouchStart = () => {
    // setIsVisible(true);
  };

  const { currentOpen, setCurrentOpen } = useAssistant();
  const isOpen = currentOpen === id;

  const $inputRef = useRef<{
    focus: () => void;
    click: () => void;
  }>(null);

  const $fakeInputRef = useRef<any>(null);

  /* const $fakeInputRef = useRef<{
    click: () => void;
  }>(null); */

  useImperativeHandle(ref, () => ({
    focus: () => {
      if ($inputRef.current) {
        $inputRef.current?.focus();
      }
    },
    click: () => {
      if ($inputRef.current) {
        $inputRef.current?.click();
      } else if ($fakeInputRef.current) {
        $fakeInputRef.current?.click();
      }
    },
  }));

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue])

  useEffect(() => {
    if (tooltip) {
      const isShown = Cookies.get("ai-assistant-tooltip");

      if (!isShown) {
        setIsTooltip(true);

        if (containerRef && containerRef?.current) {
          // @ts-ignore
          containerRef.current.disableScroll();
        }
      }
    }
  }, [tooltip]);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.currentTarget.value || "");

    if (onChange) {
      onChange(e.currentTarget.value, props?.name || "");
    }
  };

  const handleBlur = (e: SyntheticEvent<HTMLTextAreaElement>) => {
    setValue(e.currentTarget.value || "");
    // setIsVisible(false);
    if (onBlur) {
      onBlur(e.currentTarget.value, props?.name || "");
    }
  };

  // @ts-ignore
  const handleSingleAIButtonClick = (isOpenParam: boolean) => (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (isOpenParam) {
      setCurrentOpen(null);
      
      if (onOpen) {
        onOpen(false);
      }
    } else {
      setTimeout(() => {
        setCurrentOpen(id);

        if (onOpen) {
          onOpen(true);
        }
      }, 150);
    }
  };

  const handleAIButtonClick = (isOpenParam: boolean) => (e: SyntheticEvent<HTMLSpanElement>) => {
    e.stopPropagation();

    if (isTooltip) {
      Cookies.set("ai-assistant-tooltip", "true");
      setIsTooltip(false);

      if (containerRef && containerRef?.current) {
        // @ts-ignore
        containerRef.current.enableScroll();
      }
    }

    if (isOpenParam) {
      setCurrentOpen(null);
    } else {
      setTimeout(() => {
        setCurrentOpen(id);
      }, 150);
    }
  };

  const handleSelect = (item: Item | null) => {
    setCurrentOpen(null);

    if (onOpen)
      onOpen(false);

    if (item) {
      setValue(item?.text);

      if (assistant?.type !== "action")
        onChange?.(item?.text, props?.name || "", true);
    }
  };

  const handleTooltipClose = () => {
    Cookies.set("ai-assistant-tooltip", "true");
    setIsTooltip(false);

    if (containerRef && containerRef?.current) {
      // @ts-ignore
      containerRef.current.enableScroll();
    }
  };

  const getTitle = () => {
    switch (assistant?.type) {
      case DataType.GOAL:
        return "Suggest goals";
      case DataType.MEASUREMENT:
        return "Suggest measurements";
      case DataType.REWARD:
        return "Suggest rewards";
      case DataType.CONSEQUENCE:
        return "Suggest consequences";
      case DataType.ACTION:
        return "Suggest actions";
    }

    return "";
  };

  if (onlyButton) {
    return (
      <>
        {/* @ts-ignore */}
        <Button onClick={handleSingleAIButtonClick(isOpen)} styleType="secondary">
          Suggest
          <img src={assistantIcon} alt="AI" />
        </Button>

        <Assistant
          active={isOpen}
          assistant={assistant}
          onSelect={handleSelect}
        />
      </>
    );
  }

  return (
    <>
      <Styled.Wrapper onTouchStart={handleTouchStart} $active={isOpen || isTooltip}>
        {assistant?.type !== DataType.MEASUREMENT ? <InputArea
          value={value}
          selected={isOpen || isTooltip}
          placeholder={placeholder}
          onChange={handleChange}
          onBlur={handleBlur}
          max={max}
          ref={$inputRef}
          size={size}
          {...props}
          style={{
            ...props?.style,
            wordBreak: "break-word"
          }}
        /> :
          <Styled.FakeInput title={value} ref={$fakeInputRef}>
            {value}
          </Styled.FakeInput>
        }
        {USE_AI_ASSISTANT ? (
          isTooltip ? (
            <Tooltip onClose={handleTooltipClose}>
              <Styled.Toggle $isVisible={isVisible} onClick={handleAIButtonClick(isOpen)} $size={size}>
                <img src={assistantIcon} alt="AI" />
              </Styled.Toggle>
            </Tooltip>
          ) : (
            <ElementsTooltip title={getTitle()}>
              <Styled.Toggle $isVisible={isVisible} style={{right: ((props?.name == "pleasure" || props?.name == "pain") && window.innerWidth < 744) ? 5 : (props?.name != "pleasure" && props?.name != "pain" && window.innerWidth < 744)  ? 25 : 10}} onClick={handleAIButtonClick(isOpen)} $size={size}>
                <img src={assistantIcon} alt="AI" />
              </Styled.Toggle>
            </ElementsTooltip>
          )
        ) : null}
      </Styled.Wrapper>

      <Assistant
        active={isOpen}
        assistant={assistant}
        onSelect={handleSelect}
      />
    </>
  );
};

export default forwardRef(InputAreaAI);
