import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as Styled from './styled';

import Logout from 'components/Auth/Components/Logout';

import { ReactComponent as DefaultLogo } from "assets/svg/logo-arootah.svg";

import { INDEX_PATH, WELCOME_PATH } from 'utils/routes';

const Layout = ({ bg, children }) => {
  const location = useLocation();
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(false);

    setTimeout(() => {
      setAnimate(true);
    }, 100);
  }, [bg]);

  return (
    <Styled.Wrapper>
      <Styled.Background $bg={bg} $animate={animate} $animateDuration={500} />

      <Styled.Header>
        <Styled.HeaderLink to={location.pathname.includes(WELCOME_PATH) ? WELCOME_PATH : INDEX_PATH}>
          <DefaultLogo height={56} />
        </Styled.HeaderLink>

        <Logout />
        {/* {isMobile && (
          <Styled.HeaderRight>
            <Styled.HeaderLink
              to={
                WELCOME_PATH
              }
            >
              <Menu height={26} />
            </Styled.HeaderLink>
          </Styled.HeaderRight>
        )} */}
      </Styled.Header>

      <Styled.Content>
        {children}
      </Styled.Content>
    </Styled.Wrapper>
  );
}

export default Layout;
