import { FC } from 'react';
import {
  Wrapper,
  Title
} from "./styled";

export interface IOption {
  id: string;
  text: string;
}

interface IProps {
  data: IOption;
  selected: boolean;
  onSelect: (item: IOption) => void;
}

const Option: FC<IProps> = ({ data, selected, onSelect }) => {
  const { text } = data;

  const handleClick = () => {
    onSelect(data);
  };

  return (
    <Wrapper $active={selected} onClick={handleClick}>
      <Title>{text}</Title>
    </Wrapper>
  );
}

export default Option;
