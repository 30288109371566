export const ActionTypes = {
  LOADING: "GET_TIME_DISTRIBUTION_LOADING",
  SUCCESS: "GET_TIME_DISTRIBUTION_SUCCESS",
  ERROR: "GET_TIME_DISTRIBUTION_ERROR",
};

export interface ListTimeDistributionState {
  data: ITimeDistribution[];
  loading: boolean;
  success: boolean;
  error: any;
}

export interface ITimeDistribution {
  areaName: string;
  categoryName: string;
  goalName: string;
  areaColor: string;
  areaId: string;
  goalId: string;
  totalTime: number;
}
